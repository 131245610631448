import React from 'react';
import Loading from '../Loading';
import './guest.css';

function GuestUser(props) {
  const users = props.users || [];
  const currentUser = props.currentUser;

  let component;

  const selectUser = (_user) => {
    _user.unread_count = null;
    props.setUser(_user)
  }

  if (users.length === 0) {
    component = (
      <div className="guest-users">
        <Loading size="middle" />
      </div>
    );
  } else {
    component =
      <div className="guest-users">
        <ul className="people">

          {users.map((item) =>(
              <li key={item.user_id} className={`person ${item.user_id === currentUser.user_id ? 'active' : ''}`} onClick={() => { selectUser(item) }}>
                <i className={item.is_online ? 'active' : ''}></i>
                <div className="person-info">
                  <h3 className="name">
                    <strong title={item.user_id}>{item.user_id}</strong>
                    {item.unread_count && <em>{item.unread_count}</em>}
                  </h3>
                  <div className="person-history">
                    <span className="preview" title={item.last_message ? item.last_message : ''}>{item.last_message ? item.last_message : ''}</span>
                  </div>
                </div>
              </li>
            ))}

        </ul>
      </div>
  }

  return component;
}

export default GuestUser;