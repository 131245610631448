import React from 'react';
import './consult.css';

function Consult(props) {
    const BASE_URL_PATH = 'https://eggdome.ggook.com/expand/chat/consult';

    /**
     * 向iframe传递管理员数据
     * @param {*} e 
     */
    const sendData = (e) => {
        let data = {
            ad_user: props.user.chatinfo.ad_user
        };
        let targetDomain = 'https://eggdome.ggook.com/expand/chat/consult/sendmemo.php';

        e.target.contentWindow.postMessage(JSON.stringify(data), targetDomain)
    }
    
    return (
        <div className="consult-frame">
            <iframe src={BASE_URL_PATH+'/sendmemo.php?id='+props.chatId} title="consult" width="100%" height="100%" scrolling='yes' style={{border: 0}} onLoad={(e)=>{ sendData(e) }}></iframe>
        </div>
    );
}

export default Consult;