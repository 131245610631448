import { apiReqs } from '../../Api/eggdome';
import {
  createUser
} from '../../Api/sendbird';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import { decrypt, encrypt } from './encryption';
import {
  FIREBASE_CONFIG,
  ADMIN_PATH
} from '../../Constants';

/**
* 小于10数字前补0
* @param {*} num 
* @returns 
*/
export const fillZero = (num) => {
  return num < 10 ? '0' + num : num + '';
}

/**
 * 数字添加千位分隔符
 * @param {*} num 
 * @returns 
 */
export const addComma = (num) => {
  var symbol = num >= 0 ? 1 : -1;
  num = Math.abs(num);

  var initNum = (num || 0).toString(), result = '', formatNum = '';
  if (initNum.indexOf('.') > -1) formatNum = (num || 0).toString().split('.')
  var _num = formatNum ? formatNum[0] : initNum;
  while (_num.length > 3) {
    result = ',' + _num.slice(-3) + result;
    _num = _num.slice(0, _num.length - 3);
  }
  if (_num) { result = formatNum ? _num + result + '.' + formatNum[1] : _num + result; }

  return symbol === -1 ? '-' + result : result;
}

/**
 * 根据天数获取具体日期
 * @param {*} num 天数
 * @returns 
 */
const getStandTimer = (num) => {
  let standTime = null;
  let thisData = new Date();
  let msec = thisData.getTime() + 86400000 * num;
  let data = new Date(msec)
  let year = data.getFullYear();
  let month = data.getMonth() + 1;
  let day = data.getDate();
  standTime = year + '-' + fillZero(month) + '-' + fillZero(day);
  return standTime;
}

/**
 * 时间戳转日期
 * @param {*} timestamp 
 * @returns 
 */
export const timestampToDate = (timestamp) => {
  let date = new Date(timestamp);
  let Y = date.getFullYear() + '';
  let M = (date.getMonth() + 1);
  let D = date.getDate();
  let h = date.getHours();
  let i = date.getMinutes();

  return Y.slice(-2) + '/' + fillZero(M) + '/' + fillZero(D) + ' ' + fillZero(h) + ':' + fillZero(i);
}

/**
 * 时间戳转各种时间格式（用于channel列表中最后一条消息的事件）
 * @param {*} timestamp 
 * @returns 
 */
export const timestampToTime = (timestamp) => {
  let date = new Date(timestamp);
  let Y = date.getFullYear() + '';
  let M = (date.getMonth() + 1);
  let D = date.getDate();
  let h = date.getHours();
  let i = date.getMinutes();

  let time = Date.now();
  if (timestamp > time - 5 * 60 * 1000) {//5分钟内
    return '방금';

  } else if (timestamp <= time - 5 * 60 * 1000 && timestamp > time - 60 * 60 * 1000) {//1小时内
    return Math.ceil((time - timestamp) / 1000 / 60) + '분전';

  } else if (timestamp <= time - 60 * 60 * 1000 && timestamp > new Date(new Date().toLocaleDateString()).getTime()) {//今天
    return fillZero(h) + ':' + fillZero(i);

  } else if (timestamp <= new Date(new Date().toLocaleDateString()).getTime() && timestamp > new Date(new Date().toLocaleDateString()).getTime() - 86400 * 1000) {//昨天
    return '어제';

  } else if (timestamp <= new Date(new Date().toLocaleDateString()).getTime() - 86400 * 1000 && timestamp > new Date(new Date().toLocaleDateString()).getTime() - (new Date().getDay() - 1) * 24 * 60 * 60 * 1000) {//本周
    return getWeek(new Date(Y + '-' + M + '-' + D));

  } else if (timestamp <= new Date(new Date().toLocaleDateString()).getTime() - (new Date().getDay() - 1) * 24 * 60 * 60 * 1000 && timestamp > new Date(new Date().getFullYear(), 0).getTime()) {//今年
    return M + '/' + D;

  } else {
    return Y.slice(-2) + '/' + M + '/' + D;
  }
}

/**
 * 根据时间戳获取星期
 * @param {*} date 
 * @returns 
 */
const getWeek = (date) => {
  let week;
  if (date.getDay() === 0) week = "일요일"
  if (date.getDay() === 1) week = "월요일"
  if (date.getDay() === 2) week = "화요일"
  if (date.getDay() === 3) week = "수요일"
  if (date.getDay() === 4) week = "목요일"
  if (date.getDay() === 5) week = "금요일"
  if (date.getDay() === 6) week = "토요일"
  return week;
}

/**
 * 昨天0点的时间戳
 */
export const yesterday = () => {
  return new Date(new Date().toLocaleDateString()).getTime() - (86400 * 1000)
}

/**
 * 生成channel handler 句柄的唯一id
 * @returns 
 */
export const generateUUID = () => {
  var d = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16);
  });
  return uuid;
}

/**
 * 处理消息内容中的所有的html标签转文本并且保留换行
 * @param {*} msg 
 * @returns 
 */
export const messageFormat = (msg) => {
  let _msg = msg;

  _msg = _msg.replace(/<style([\s\S]*?)<\/style>/gi, '');
  _msg = _msg.replace(/<script([\s\S]*?)<\/script>/gi, '');
  _msg = _msg.replace(/<\/div>/ig, '\n');
  _msg = _msg.replace(/<\/p>/ig, '\n');
  _msg = _msg.replace(/<\/h1>/ig, '\n');
  _msg = _msg.replace(/<\/h2>/ig, '\n');
  _msg = _msg.replace(/<\/h3>/ig, '\n');
  _msg = _msg.replace(/<\/h4>/ig, '\n');
  _msg = _msg.replace(/<\/h5>/ig, '\n');
  _msg = _msg.replace(/<\/h6>/ig, '\n');
  _msg = _msg.replace(/<\/p>/ig, '\n');
  _msg = _msg.replace(/<\/li>/ig, '\n');
  _msg = _msg.replace(/<\/dt>/ig, '\n');
  _msg = _msg.replace(/<\/dd>/ig, '\n');
  _msg = _msg.replace(/<\/header>/ig, '\n');
  _msg = _msg.replace(/<\/footer>/ig, '\n');
  _msg = _msg.replace(/<\/section>/ig, '\n');
  _msg = _msg.replace(/<\/article>/ig, '\n');
  _msg = _msg.replace(/<\/ul>/ig, '\n');
  _msg = _msg.replace(/<\/ol>/ig, '\n');
  _msg = _msg.replace(/<\/dl>/ig, '\n');
  // eslint-disable-next-line no-useless-escape
  _msg = _msg.replace(/<br[^>]*[\/]?>/gi, "\n");
  // eslint-disable-next-line no-useless-escape
  _msg = _msg.replace(/<img([^>]*)[\/]?>/gi, "\n$1\n");
  _msg = _msg.replace(/<[^>]+>/ig, '');
  _msg = _msg.replace(/&nbsp;/ig, ' ');

  return _msg;
}


/**
 * 输入框的消息根据图片和文字拆分成数组
 * @param {*} msg 
 * @returns 
 */
export const messageToArray = (msg) => {
  let msgsArr = msg.split('\n');
  let msgsData = [];

  msgsArr.forEach((val, index) => {
    if (val.indexOf('src="') !== -1) {
      //假如消息为图片，则提取图片链接放入到数组
      // eslint-disable-next-line no-useless-escape
      let src = val.match(/src=[\'|\"][^\'|\"]*[\'|\"]/gi);
      let imageUrl = src[0].replace('src="', '').replace('src=\'', '').replace('"', '').replace('\'', '');
      let _obj = {
        type: 'image',
        value: imageUrl
      };

      msgsData.push(_obj);
    } else {
      let nextMsg = msgsArr[index + 1];//下一次即将要处理的消息
      let lastData = msgsData[msgsData.length - 1];//已被处理的上一次消息

      if (val.trim() === '') {
        //文字消息内容为空字符串，并且下一个要处理的消息不为图片，上一个已处理的消息为文字消息，则上一次消息的最后只添加换行\n
        if ((nextMsg && nextMsg.indexOf('src="') === -1) && (lastData && lastData['type'] === 'text')) {
          let _obj = {
            type: 'text',
            value: lastData['value'] + '\n'
          };
          msgsData[msgsData.length - 1] = _obj;
        }
      } else {
        if (lastData && lastData['type'] === 'text') {
          //文字消息不为空字符串，并且上一个已处理的消息为文字消息，则两条消息以换行\n合并
          let _obj = {
            type: 'text',
            value: lastData['value'] + '\n' + val
          };
          msgsData[msgsData.length - 1] = _obj;
        } else {
          //文字消息不为空字符串，并且上一个已处理的消息不为文字消息，则追加该消息
          let _obj = {
            type: 'text',
            value: val
          };
          msgsData.push(_obj)
        }
      }
    }
  });

  return msgsData;
}


/**
   * 递归创建用户
   * @param {*} users 
   * @param {*} callback 
   */
export const recursiveCreateUser = (users, callback) => {
  if (users.length === 0) {
    callback();
  } else {
    let user = users.shift();
    createUser(user['user_id'], user['nickname'], () => {
      recursiveCreateUser(users, callback);
    });
  }
}


/**
   * 更新 firebase token
   * @param {*} ad_id 
   */
export const updateFirebaseToken = (ad_id) => {
  initializeApp(FIREBASE_CONFIG);

  const messaging = getMessaging();

  getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID })
    .then((currentToken) => {
      if (currentToken) {
        // Send the token to your server and update the UI if necessary
        // ...
        // console.log('firebase: '+currentToken);

        let tokenToSend = {
          adId: ad_id,
          userToken: currentToken
        }
        let tokenEncrypted = encrypt(JSON.stringify(tokenToSend));

        apiReqs.updateUserToken({
          data: {
            chatid: tokenEncrypted
          },
          success: (res) => {
            let resEncrypted = res.userinfo;
            let resDecrypted = decrypt(resEncrypted);
            let resArr = JSON.parse(resDecrypted);

            if (resArr.succeed) {
              console.log('token updated.');
            }
          },
          fail: (err) => {
            console.log(err);
          }
        });
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
        // ...
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // ...
    });
}


/**
   * 图片加载完成后的事件，初始化消息列表时滚动条滚动到底部，加载更多消息时滚动到指定位置
   * @param {*} callback 
   */
export const loadImage = (callback) => {
  let images = document.getElementById('chat').querySelectorAll('img');

  images.forEach((item) => {
    item.onload = () => {
      callback && callback();
    }
  });
}


/**
   * 获取管理链接地址
   * @param {*} leavingLevel 
   * @param {*} leavingId 
   * @returns 
   */
export const getAdminUrl = (leavingLevel, leavingId) => {
  var nowDate = getStandTimer(0);
  var lastThreeMonthDate = getStandTimer(-90);

  var adminLink = '';
  if (leavingLevel === 'import') {
    adminLink = ADMIN_PATH + '/leaving/list.php?sk=li_id&sv=' + leavingId + '&st1=' + lastThreeMonthDate + '&st2=' + nowDate;
  } else if (leavingLevel === 'deliurl') {
    adminLink = ADMIN_PATH + '/leaving/list_deliurl.php?sk=li_id&sv=' + leavingId + '&st1=' + lastThreeMonthDate + '&st2=' + nowDate;
  } else {
    adminLink = ADMIN_PATH + '/leaving/list_delivery.php?sk=li_id&sv=' + leavingId + '&st1=' + lastThreeMonthDate + '&st2=' + nowDate;
  }

  return adminLink;
}


export const sleep = (time) => {
  var timeStamp = new Date().getTime();
  var endTime = timeStamp + time;
  while (true) {
    if (new Date().getTime() > endTime) {
      return;
    }
  }
}