import React from 'react';
import { deleteMessage } from '../../Api/sendbird';
import './context.css';

function ContextMenu(props) {
    const expired = 3600000;//消息可撤回时间为5分钟

    const contextList = [
        {
            text: '복사',
            icon: require('../../Common/img/copy.png'),
            active: true,
            handleClick: ()=>{
                document.execCommand("Copy");//复制已选内容
            }
        },
        {
            text: '댓글',
            icon: require('../../Common/img/quote.png'),
            active: true,
            handleClick: ()=>{
                props.handleMessage(props.message_id);
            }
        },
        {
            text: '취소하기',
            icon: require('../../Common/img/back.png'),
            active: parseInt(props.created_at) + expired > Date.now() && props.withdraw,
            handleClick: ()=>{
                const message_id = props.message_id;

                if (message_id === '') return false;

                const channel_url = props.currentChannel.channel_url;

                deleteMessage(channel_url, message_id);
            }
        },
    ];


    return (
        <ul className="context-menu">
            {contextList.map((item, key)=>(
                <li key={key} onClick={()=>{item.handleClick()}} style={{display: item.active ? 'flex': 'none'}}>
                    <img src={item.icon} alt="" />
                    <span>{item.text}</span>
                </li>
            ))}
        </ul>
    );
}

export default ContextMenu;