import React, { useState } from 'react';
import { timestampToTime } from '../../Common/js/helper';
import Loading from '../Loading';
import { updateChannel } from '../../Api/sendbird';
import './channel.css';

function Channel(props) {
  const channels = props.channels;
  const currentChannel = props.currentChannel;
  const [channelFilter, setChannelFilter] = useState('');//channel筛选词

  /**
   * 把文字改为可编辑模式
   * @param {*} e 
   */
  const editChannelName = (e) => {
    let obj = e.target;
    
    obj.style = '';
    obj.setAttribute('contentEditable', 'true');
    obj.focus();
  }


  /**
   * 更新 sendbird 中的 channel_name 
   * @param {*} e 
   */
  const updateChannelName = async (e) => {
    let obj = e.target;
    let channel_url = obj.getAttribute('channel_url');
    let channel_name = obj.innerHTML;
    let regex = /(<([^>]+)>)/ig
    channel_name = channel_name.replace(regex, '');

    await updateChannel(channel_url, {name: channel_name});

    obj.setAttribute('contentEditable', 'false');
    obj.style.overflow = 'hidden';
    obj.style.whiteSpace = 'nowrap';
    obj.style.textOverflow = 'ellipsis';

    props.setName(channel_name);
  }

  let component;

  if (channels.length === 0) {
    component = (
      <div className="channels">
        <Loading size="middle" />
      </div>
    );
  } else {
    let renderChannels;
    if (channelFilter === '') {
      renderChannels = [...channels];
    } else {
      renderChannels = channels.filter((item) => {
        return item.name.indexOf(channelFilter) !== -1;
      });
    }

    component =
      <div className="channels">
        <div className="top">
          <input type="text" placeholder="Search" onChange={(e) => { setChannelFilter(e.target.value) }} />
        </div>
        <ul className="people">

          {renderChannels.map((item) => {
            let msg = '';

            if (item.last_message) {
              switch (item.last_message.custom_type) {
                case 'attachmentFilename':
                  let filePath = item.last_message.file.url.split('.');
                  msg = '파일 ' + filePath[filePath.length - 1];
                  break;

                case 'clipboardImages':
                  let jsonData = JSON.parse(item.last_message.data);
                  let imagePath = jsonData.clipboardImages[0].split('.');
                  msg = '이미지 ' + imagePath[imagePath.length - 1];
                  break;

                default:
                  msg = item.last_message.message;
                  break;
              }
            }

            return (
              <li key={item.channel_url} className={`person ${item.channel_url === currentChannel.channel_url ? 'active' : ''}`} onClick={() => { props.setChannel(item) }}>
                <img src={item.cover_url} alt="" />
                <div className="person-info">
                  <h3 className="name">
                    <div
                      title={item.name}
                      style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
                      channel_url={item.channel_url}
                      onDoubleClick={(e)=>{editChannelName(e)}}
                      onBlur={(e)=>{updateChannelName(e)}}
                      suppressContentEditableWarning
                      contentEditable="false"
                    >{item.name}</div>
                    {item.unread_message_count > 0 ? <em>{item.unread_message_count}</em> : ''}
                  </h3>
                  <div className="person-history">
                    <span className="preview" title={msg}>{msg}</span>
                    <span className="time">{item.last_message ? timestampToTime(item.last_message.created_at) : '-'}</span>
                  </div>
                </div>
              </li>
            );
          })}

        </ul>
      </div>
  }

  return component;
}

export default Channel;