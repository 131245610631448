import React from 'react';
import './chat_end.css'

function ChatEnd(props) {
    return (
        <div className="chat-end">
            <h3>상담이 종료되었습니다.</h3>
            <button onClick={()=>{window.location.reload()}}>다시 연결하기</button>
        </div>
    );
}

export default ChatEnd;