import axios from 'axios';

const BOT_ID = 'eggchat_bot_1';
const CHAT_PATH = 'https://chat.eggdome.com';

const _channelDatas = [];
const _channelLimit = 100;
/**
 * 递归获取所有的channel
 * @param {*} user_id 
 * @param {*} next_token 
 * @returns 
 */
export const getChannelDatas = async (user_id, next_token) => {
    let next_param = next_token ? '&token=' + next_token : '';
    const response = await axios.get(
        `https://api-${process.env.REACT_APP_ID}.sendbird.com/v3/users/${user_id}/my_group_channels?limit=${_channelLimit}&order=latest_last_message${next_param}&show_member=true`,
        {
            headers: {
                'Content-Type': 'application/json; charset=utf8',
                'Api-Token': process.env.REACT_APP_TOKEN,
            },
        }
    );

    if (response.data.next !== '') {
        await getChannelDatas(user_id, response.data.next);
    }

    _channelDatas.unshift(...response.data.channels);

    return _channelDatas;
}


/**
 * 获取 channel 的详细信息，包含 channel 所属的用户
 * @param {*} channel_url
 * @param {*} callback
 */
export const getChannel = (channel_url, callback) => {
    axios.get(
        `https://api-${process.env.REACT_APP_ID}.sendbird.com/v3/group_channels/${channel_url}?show_member=true`,
        {
            headers: {
                'Content-Type': 'application/json; charset=utf8',
                'Api-Token': process.env.REACT_APP_TOKEN,
            },
        }
    )
    .then(res => {
        callback && callback(res);
    })
    .catch(e => {
        callback && callback(e);
    });
}


/**
 * 新注册用户需要创建channel
 * @param {*} username 
 * @param {*} channelName 
 * @param {*} ad_user_all
 * @returns 
 */
export const createChannel = async (username, channelName, ad_user_all) => {
    let avatarUrl = getAvatar();

    const createRes = await axios.post(
        `https://api-${process.env.REACT_APP_ID}.sendbird.com/v3/group_channels`,
        {
            user_ids: [username, ...ad_user_all],
            name: channelName,
            cover_url: avatarUrl,
            is_distinct: true
        },
        {
            headers: {
                'Content-Type': 'application/json; charset=utf8',
                'Api-Token': process.env.REACT_APP_TOKEN,
            },
        }
    );

    //加入到自动回复消息channel
    joinChannelBot(createRes.data.channel_url);

    return createRes;
}

/**
   * 加入到自动回复消息的channel
   * @param {*} currentChannelUrl 
   */
const joinChannelBot = (currentChannelUrl) => {
    axios.post(
        `https://api-${process.env.REACT_APP_ID}.sendbird.com/v3/bots/${BOT_ID}/channels`,
        {
            channel_urls: [currentChannelUrl]
        },
        {
            headers: {
                'Content-Type': 'application/json; charset=utf8',
                'Api-Token': process.env.REACT_APP_TOKEN,
            },
        }
    );
}


/**
 * 更新channel
 * @param {*} channel_url
 * @param {*} data
 * @returns 
 */
export const updateChannel = async (channel_url, data) => {
    let updateData = data ? data : {cover_url: getAvatar()};

    await axios.put(
        `https://api-${process.env.REACT_APP_ID}.sendbird.com/v3/group_channels/${channel_url}`,
        updateData,
        {
            headers: {
                'Content-Type': 'application/json; charset=utf8',
                'Api-Token': process.env.REACT_APP_TOKEN,
            },
        }
    );
}


const getAvatar = () => {
    let rangeNum = 75;
    let avatarNum = Math.ceil(Math.random() * rangeNum);
    avatarNum = avatarNum < 10 ? '0' + avatarNum : '' + avatarNum;
    return CHAT_PATH + '/avatar/' + avatarNum + '.png';
}


/**
 * 删除 channel
 * @param {*} channel_url
 * @returns 
 */
 export const deleteChannel = async (channel_url, callback) => {
    await axios.delete(
        `https://api-${process.env.REACT_APP_ID}.sendbird.com/v3/group_channels/${channel_url}`,
        {
            headers: {
                'Content-Type': 'application/json; charset=utf8',
                'Api-Token': process.env.REACT_APP_TOKEN,
            },
        }
    ).then(res=>{
        if (res.code) {
            callback && callback();
        }
    });
}


/**
 * 创建一个openChannel
 * @param {*} username 
 * @param {*} channelName 
 * @param {*} ad_user_all
 * @returns 
 */
export const createOpenChannel = async (name, channel_url) => {
    const createRes = await axios.post(
        `https://api-${process.env.REACT_APP_ID}.sendbird.com/v3/open_channels`,
        {
            name,
            channel_url
        },
        {
            headers: {
                'Content-Type': 'application/json; charset=utf8',
                'Api-Token': process.env.REACT_APP_TOKEN,
            },
        }
    );

    return createRes;
}


export const getOpenChannels = async (callback) => {
    axios.get(
        `https://api-${process.env.REACT_APP_ID}.sendbird.com/v3/open_channels`,
        {
            headers: {
                'Content-Type': 'application/json; charset=utf8',
                'Api-Token': process.env.REACT_APP_TOKEN,
            },
        }
    )
    .then(res => {
        callback && callback(res);
    })
    .catch(e => {
        callback && callback(e);
    });
}


/**
 * 根据user_id获取sendbird中的用户
 * @param user_ids
 * @param callback
 */
 export const getUsers = async (user_ids, callback) => {
    axios.get(
        `https://api-${process.env.REACT_APP_ID}.sendbird.com/v3/users?user_ids=${user_ids}`,
        {
            headers: {
                'Content-Type': 'application/json; charset=utf8',
                'Api-Token': process.env.REACT_APP_TOKEN,
            },
        }
    ).then(res=>{
        callback && callback(res);
    });
}


/**
 * 在sendbird中创建用户（待删除：后端会员加入和添加管理员时添加到sendbird会员后删除此方法）
 * @param user_ids
 * @param nickname
 * @returns 
 */
export const createUser = async (user_id, nickname, callback) => {
    await axios.post(
        `https://api-${process.env.REACT_APP_ID}.sendbird.com/v3/users`,
        {
            user_id: user_id,
            nickname: nickname || user_id,
            profile_url: ''
        },
        {
            headers: {
                'Content-Type': 'application/json; charset=utf8',
                'Api-Token': process.env.REACT_APP_TOKEN,
            },
        }
    ).then(res=>{
        // console.log('create user success: ', res);
        callback && callback();
    }).catch(e => {
        // console.log('create user error: ', e);
        callback && callback();
    });
}


/**
 * 获取channel中的用户信息, this method return fuck the promise object
 * @param {*} currentChannelUrl 
 * @param {*} callback 
 */
export const getChannelMembers = (currentChannelUrl, callback) => {
    axios.get(
        `https://api-${process.env.REACT_APP_ID}.sendbird.com/v3/group_channels/${currentChannelUrl.channel_url}/members`,
        {
            headers: {
                'Content-Type': 'application/json; charset=utf8',
                'Api-Token': process.env.REACT_APP_TOKEN,
            },
        }
    ).then(res => {
        let members = res.data.members;
        let _members = [];
        for (let i = 0; i < members.length; i++) {
            _members.push(members[i].user_id);
        }

        callback(_members);
    });
}


/**
   * 管理员加入到当前的group_channel
   * @param {*} currentChannelUrl 
   * @param members 
   */
export const inviteChannel = async (currentChannelUrl, members) => {
    axios.post(
        `https://api-${process.env.REACT_APP_ID}.sendbird.com/v3/group_channels/${currentChannelUrl.channel_url}/invite`,
        {
            user_ids: members
        },
        {
            headers: {
                'Content-Type': 'application/json; charset=utf8',
                'Api-Token': process.env.REACT_APP_TOKEN,
            },
        }
    );
}


/**
 * 当前的group_channel中移除管理员
 * @param {*} currentChannelUrl 
 * @param members 
 */
export const leaveChannel = (currentChannelUrl, members) => {
    axios.put(
        `https://api-${process.env.REACT_APP_ID}.sendbird.com/v3/group_channels/${currentChannelUrl.channel_url}/leave`,
        {
            user_ids: members
        },
        {
            headers: {
                'Content-Type': 'application/json; charset=utf8',
                'Api-Token': process.env.REACT_APP_TOKEN,
            },
        }
    );
}


/**
 * sendbird服务器中消息设置为已读
 * @param {*} channel 
 * @param {*} users 
 */
 export const setMsgAsRead = (channelUrl, users) => {
    users.forEach(async (v)=>{
        await axios.put(
            `https://api-${process.env.REACT_APP_ID}.sendbird.com/v3/users/${v}/mark_as_read_all`,
            {
                channel_urls: [channelUrl]
            },
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf8',
                    'Api-Token': process.env.REACT_APP_TOKEN,
                },
            }
        );
    });
}


/**
 * 获取消息列表（初始化/加载更多）
 * @param {*} data 
 * @param {*} message_ts 
 * @returns 
 */
export const getMessageDatas = (data, message_ts) => {
    return axios.get(
        `https://api-${process.env.REACT_APP_ID}.sendbird.com/v3/group_channels/${data.channel.channel_url}/messages?prev_limit=${data.prev_limit}&next_limit=${data.next_limit}&message_ts=${message_ts}&custom_types=*&include_reactions=true&include_poll_details=false`,
        {
            headers: {
                'Content-Type': 'application/json; charset=utf8',
                'Api-Token': process.env.REACT_APP_TOKEN,
            },
        }
    );
}


/**
 * 获取open channel消息列表
 * @param {*} channel_url 
 * @param {*} custom_types 
 * @returns 
 */
 export const getOpenMessageDatas = (channel_url, admin_id, user_id, flag) => {
    let message_ts = Date.now();
    let sender_ids = admin_id + ',' + user_id;
    let custom_types = '*';
    if (flag !== '*') {
        custom_types = 'flag@'+flag+';user_id@'+user_id;
    }

    return axios.get(
        `https://api-${process.env.REACT_APP_ID}.sendbird.com/v3/open_channels/${channel_url}/messages?prev_limit=200&message_ts=${message_ts}&sender_ids=${sender_ids}&custom_types=${custom_types}&include_reactions=true&include_poll_details=false`,
        {
            headers: {
                'Content-Type': 'application/json; charset=utf8',
                'Api-Token': process.env.REACT_APP_TOKEN,
            },
        }
    );
}


/**
 * 请求自动回复消息
 * @param {*} messageData 
 */
export const sendBotMessage = (messageData) => {
    axios.post(
        `https://api-${process.env.REACT_APP_ID}.sendbird.com/v3/bots/${BOT_ID}/send`,
        messageData,
        {
            headers: {
                'Api-Token': process.env.REACT_APP_TOKEN,
            },
        }
    );
}


/**
 * 发送消息
 * @param {*} channel_url 
 * @param {*} messageData 
 * @returns 
 */
export const sendMessageHandle = (channel_url, messageData, callback) => {
    axios.post(
        `https://api-${process.env.REACT_APP_ID}.sendbird.com/v3/group_channels/${channel_url}/messages`,
        messageData,
        {
            headers: {
                'Content-Type': 'application/json; charset=utf8',
                'Api-Token': process.env.REACT_APP_TOKEN,
            },
        }
    )
    .then(res=>{
        callback && callback();
    })
    .catch(e=>{
        callback && callback();
    });
}


/**
 * 发送消息
 * @param {*} channel_url 
 * @param {*} messageData 
 * @param {*} type 
 * @returns 
 */
 export const sendOpenMessageHandle = (channel_url, messageData, callback) => {
    axios.post(
        `https://api-${process.env.REACT_APP_ID}.sendbird.com/v3/open_channels/${channel_url}/messages`,
        messageData,
        {
            headers: {
                'Content-Type': 'application/json; charset=utf8',
                'Api-Token': process.env.REACT_APP_TOKEN,
            },
        }
    )
    .then(res=>{
        callback && callback();
    })
    .catch(e=>{
        callback && callback();
    });
}


/**
 * 删除消息
 * @param {*} channel_url 
 * @param {*} message_id 
 * @returns 
 */
export const deleteMessage = (channel_url, message_id) => {
    axios.delete(
        `https://api-${process.env.REACT_APP_ID}.sendbird.com/v3/group_channels/${channel_url}/messages/${message_id}`,
        {
          headers: {
            'Content-Type': 'application/json; charset=utf8',
            'Api-Token': process.env.REACT_APP_TOKEN,
          },
        }
    );
}