import React from 'react';
import ReactDOM from 'react-dom/client';
import { PATHNAME } from './Constants';
import Chat from './Chat';
import Guest from './Guest';
// import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

switch (PATHNAME) {
    case '/guest': root.render(<Guest />); break;
    default: root.render(<Chat />); break;
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
