import React from 'react';
import './loading.css'

function LoadingMain(props) {
    return (
        <div className="loading-main">
            <img src={require('../../Common/img/loading01.gif')} alt="" />
            {props.auth === 2 && <strong>문의대기중입니다, 잠시만 기다려 주세요</strong>}
        </div>
    );
}

export default LoadingMain;