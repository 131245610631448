import React, { useEffect, useRef, useState } from 'react';
import { apiReqs } from './Api/eggdome';
import {
  PATHNAME,
  HOME_PATH,
  allowedFiles,
  allowedVideo,
  allowedMIME,
  PHOTO_MAP
} from './Constants';
import { decrypt, encrypt } from './Common/js/encryption';
import './Common/css/reset.min.css';
import './Common/css/style.css';
import SendBird from 'sendbird';
import ReactTooltip from 'react-tooltip';
import Channel from './Components/Channel';
import Loading from './Components/Loading';
import LoadingMain from './Components/LoadingMain';
import {
  addComma,
  timestampToDate,
  generateUUID,
  messageFormat,
  messageToArray,
  recursiveCreateUser,
  updateFirebaseToken,
  loadImage,
  getAdminUrl
} from './Common/js/helper';
import Error from './Components/Error';
import Info from './Components/Info';
import Consult from './Components/Consult';
import ContextMenu from './Components/ContextMenu';
import {
  getChannelDatas,
  getChannel,
  createChannel,
  updateChannel,
  getChannelMembers,
  inviteChannel,
  leaveChannel,
  setMsgAsRead,
  getMessageDatas,
  sendMessageHandle,
  sendBotMessage,
  deleteChannel,
  deleteMessage
} from './Api/sendbird';


function Chat() {
  const [auth, setAuth] = useState(0);//认证状态：0：未认证，1：已认证，2：认证失败
  const [chatId, setChatId] = useState('');//url path 中的chatid
  const [chatIdEncrypted, setChatIdEncrypted] = useState('');//请求用户信息的加密chatid（由于重复使用[自动回复中使用]，就这样设置为state）
  const [userInfo, setUserInfo] = useState({});//eggdome api的用户信息
  const [headerImg, setHeaderImg] = useState('');//用户头像
  const [leavingInfo, setLeavingInfo] = useState({});//eggdome api的用户信息
  const [userId, setUserId] = useState('');//当前登录用户的用户名
  const [channels, setChannels] = useState([]);//channel列表
  const [updatedChannel, setUpdatedChannel] = useState({});//channel更新标记，用于接收新消息后，更新channel列表中的最近消息内容
  const [currentChannel, setCurrentChannel] = useState({});//当前channel
  const [channelUUID, setChannelUUID] = useState('');//当前channel句柄id
  const [channelDraft, setChannelDraft] = useState({});//草稿箱映射表
  const [firstMessageId, setFirstMessageId] = useState('');//第一条消息id，用于消息框顶部加载更多后，控制滚动条到这个位置
  const [dashboardMessage, setDashboardMessage] = useState(null);//统计消息
  const [dashboardMark, setDashboardMark] = useState(false);//是否已发出统计数据消息
  const [messages, setMessages] = useState(null);//消息列表
  const [lastMessage, setLastMessage] = useState(null);//最后发送的消息，用于标记已读状态
  const [lastMessageChannel, setLastMessageChannel] = useState(null);//最后发送的消息对应的channel，也用于标记已读状态
  const [readerStateTrigger, setReaderStateTrigger] = useState(0);//最后发送的消息对应的channel，也用于标记已读状态
  const [messageReaderMap, setMessageReaderMap] = useState({});//消息已读状态映射表
  const [hasMoreMessage, setHasMoreMessages] = useState(true);//是否有更多消息（控制上拉滚动事件）
  const [messageTime, setMessageTime] = useState(Date.now());//消息列表中第一条消息的时间，用于消息加载更多api的参数
  const [newMessage, setNewMessage] = useState({});//新消息标记，用于更新消息列表以及滚动条下拉到底部
  const [delMessage, setDelMessage] = useState(0);//删除消息标记，用于删除新消息
  const [messageLoading, setMessageLoading] = useState(false);//消息加载更多转动图标显示状态
  const [messageSending, setMessageSending] = useState(false);//消息发送loading显示状态，也防止消息重复发送
  const [infoView, setInfoView] = useState(true);//用户端右侧消息信息显示状态
  const [consultView, setConsultView] = useState(true);//管理员端右侧搜索框显示状态
  const [contextMessageId, setContextMessageId] = useState('');//鼠标点击右键的消息id
  const [contextMessageCreatedAt, setContextMessageCreatedAt] = useState(0);//鼠标点击右键的消息创建时间
  const [contextMessageDraw, setContextMessageDraw] = useState(false);//鼠标点击撤回消息是否可以
  const [contentEditable, setContentEditable] = useState('false');//输入框激活状态
  const pathname = PATHNAME.replace('/', '');//url参数中删除左边的“/”
  const [yyMessage,setYyMessage] = useState(null);
  const [quoteBtnView,setQuoteBtnView] = useState(false);
  const [yyMessageLoading, setYyMessageLoading] = useState(false) //引用消息Loading显示状态
  const messagesEndRef = useRef(null);//消息框底部标记，初始化或接受新消息时滚动到这个位置
  const yyMessageIdRef = useRef(null); //点击的引用消息id，用于加载该条消息后，控制滚动条到这个位置
  const curMessageIdRef = useRef(null); //点击引用后 当前消息id，用于返回到这个位置
  const lastYytimeRef = useRef(Date.now()); //引用消息列表中第一条消息的时间，用于引用消息加载更多api的参数
  const yyMsgRef = useRef(null); //用于存储点击引用消息后 到当前消息的列表
  const quoteDisRef = useRef(false); //用于开启显示与隐藏回到引用位置div 滚动条事件
  const yyLimitRef = useRef(50); //引用消息limit
  const limitRef = useRef(30); //向上滚动limit
  const clipInputRef = useRef(null); //消息输入框
  //url参数，判断是否为管理员（module=admin）
  const queryParams = new URLSearchParams(window.location.search);
  const module = queryParams.get('module');
  const utis_id = queryParams.get('utis_id');
  const expired = 3600000;//消息可撤回时间为5分钟

  //初始化sendbird
  const sb = new SendBird({ appId: process.env.REACT_APP_ID });
  const channelHandler = new sb.ChannelHandler();
  // let yyMessage = null;  //引用消息
  /** 用户认证、连接socket */
  useEffect(() => {
    //拆分、组合并加密url中path值
    let splitChatToken = pathname.slice(-32);
    let splitChatId = pathname.slice(0, -32);
    setChatId(splitChatId);

    let chatData = {
      chatId: splitChatId,
      chatToken: splitChatToken
    };
    let _chatIdEncrypted = encrypt(JSON.stringify(chatData));
    setChatIdEncrypted(_chatIdEncrypted);

    apiReqs.getChatinfo({//获取数据库中的用户信息
      module: module,
      data: {
        chatid: _chatIdEncrypted
      },
      success: async (res) => {
        const userinfoJson = JSON.parse(decrypt(res.userinfo));
        // console.log(userinfoJson);
        let _userId = userinfoJson.chatinfo.mb_id;

        if (!_userId || _userId === '') {
          setAuth(2);//用户认证失败
          return;
        }
        setAuth(1);//用户已认证

        //state设置用户信息
        setUserInfo(userinfoJson);
        //state设置用户头像
        let headerimg;
        if (userinfoJson.chatinfo.mb_photo && userinfoJson.chatinfo.mb_photo !== '') {
          headerimg = userinfoJson.chatinfo.mb_photo;
        } else {
          headerimg = PHOTO_MAP[userinfoJson.chatinfo.mb_level]
        }
        setHeaderImg(headerimg);

        let channelName = userinfoJson.chatinfo.mb_name + '(' + userinfoJson.chatinfo.mb_user + '.' + userinfoJson.chatinfo.mb_level + ')';
        let all_users = userinfoJson.chatinfo.ad_user_all.map((item) => {
          return { user_id: item, nickname: item };
        });
        all_users.unshift({ user_id: _userId, nickname: channelName });

        recursiveCreateUser(all_users, () => {//首先在 sendbird 中创建用户
          /** connect sendbird web socket，获取未读消息数据之前连接socket，要不然获取不到 */
          sb.connect(
            (module === 'admin' ? userinfoJson.chatinfo.ad_user : userinfoJson.chatinfo.mb_id),
            (module === 'admin' ? userinfoJson.chatinfo.admin_token : ''),
            (user, error) => {
              if (error) {
                console.log('connect failed');
                console.log(error);
                return false;
              }
              console.log('socket connect success');

              const channelOwner = module === 'admin' ? userinfoJson.chatinfo.ad_user : userinfoJson.chatinfo.mb_id;

              let userChannelUrl = userinfoJson.chatinfo.c_channel_url && userinfoJson.chatinfo.c_channel_url !== '' ? userinfoJson.chatinfo.c_channel_url : 'no_channel_url';
              getChannel(userChannelUrl, async (result) => {//获取当前用户所在的 channel
                let curChannel = !result.code ? result.data : undefined;
                let channelCreated = false;

                if (!curChannel || curChannel.members.filter(item => item.user_id === _userId).length === 0) {//假如sendbird 中没有对应的channel，或者虽然有，但不包含当前用户，则新创建channel

                  //创建新的channel（假如channel成员和之前相同，则返回已存在的channel）
                  let createRes = await createChannel(_userId, channelName, userinfoJson.chatinfo.ad_user_all);

                  channelCreated = true;
                  curChannel = createRes.data;

                  let channelToSend = {
                    chatId: splitChatId,
                    channelUrl: curChannel.channel_url
                  };

                  let channelEncrypted = encrypt(JSON.stringify(channelToSend));

                  apiReqs.updateChannelUrl({
                    data: {
                      chatid: channelEncrypted
                    },
                    success: (res) => {
                      //console.log(res);
                    },
                    fail: (res) => {
                      console.log(res);
                    }
                  });
                }

                if (!channelCreated) {//不是新创建的channel，则调整channel所属的member
                  getChannelMembers(curChannel, (members) => {
                    let admins = userinfoJson.chatinfo.ad_user_all;
                    let adminFilter = [];

                    members = members.filter(item => {
                      return item !== 'eggchat_bot_1' && item !== userinfoJson.chatinfo.mb_id;
                    });

                    //如果数据库中存在的管理员在group_channel中存在，则移除此管理员不再操作，其余的添加到group_channel
                    //如果group_channel中存在的管理员在数据库中存在，则移除此管理员不再操作，其余的从group_channel中移除
                    for (let i = 0; i < admins.length; i++) {
                      for (let j = 0; j < members.length; j++) {
                        if (admins[i] === members[j]) {
                          adminFilter.push(admins[i]);
                        }
                      }
                    }

                    admins = admins.filter(item => !adminFilter.includes(item));
                    members = members.filter(item => !adminFilter.includes(item));

                    if (admins.length > 0) inviteChannel(curChannel, admins);
                    if (members.length > 0) leaveChannel(curChannel, members);
                  });
                }

                if (module === 'admin') {//管理员则获取所有的channel列表
                  await getChannels(channelOwner, (_channels) => {//获取channel列表
                    // channel列表中无法匹配当前channel，则添加之
                    let [_curChannel] = _channels.filter((item) => {
                      return item.channel_url === curChannel.channel_url;
                    });
                    if (!_curChannel) {
                      setChannels([curChannel, ..._channels]);
                    }
                    // console.log(_channels);
                  });
                } else {
                  setChannels([curChannel]);
                }

                setCurrentChannel(curChannel);//设置当前channel，这个

                let user_id = module === 'admin' ? userinfoJson.chatinfo.ad_user : userinfoJson.chatinfo.mb_id;
                setUserId(user_id);

                //当前channel的未读消息设置为0
                let users = module === 'admin' ? userinfoJson.chatinfo.ad_user_all : [userinfoJson.chatinfo.mb_id];
                setMsgAsRead(curChannel.channel_url, users);

                getMessages({//获取初始化消息
                  init: true,
                  time:Date.now(),
                  prev_limit: limitRef.current,
                  next_limit: 0,
                  channel: curChannel
                });

                //更新聊天窗口头像
                //官方默认值： https://static.sendbird.com/sample/cover/cover_11.jpg / cover_11~cover_15
                if (curChannel.cover_url !== headerimg) {
                  updateChannel(curChannel.channel_url, {cover_url: headerimg});
                }
              });

              module === 'admin' && updateFirebaseToken(userinfoJson.chatinfo.ad_id);
            }
          );
        });
      },
      fail: (res) => {
        setAuth(2);//用户认证失败
        console.log('fail ' + res);
      }
    });
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  /** 获取用户的leaving信息 */
  useEffect(() => {
    if (module === 'admin') return;//admin不需要获取leaving info

    let splitChatToken = pathname.slice(-32);
    let splitChatId = pathname.slice(0, -32);
    let chatData = {
      chatId: splitChatId,
      chatToken: splitChatToken
    };
    let _chatIdEncrypted = encrypt(JSON.stringify(chatData));

    apiReqs.getLeavingInfo({//获取数据库中的用户leaving信息
      data: {
        chatid: _chatIdEncrypted
      },
      success: (res) => {
        const leaving = JSON.parse(decrypt(res.leavinginfo));
        // console.log(leaving);
        setLeavingInfo(leaving);

        let _msg = {
          created_at: 0,
          custom_type: 'consultLeavingOverview',
          message_id: 9999999999999,
          message: '최근 3개월에 문의내역입니다.',
          data: JSON.stringify({
            consultLeavingOverview: {
              leavingOverview: leaving.leavingOverview
            }
          }),
          user: {
            user_id: 'bot',
          }
        }

        setDashboardMessage(_msg);
      },
      fail: (res) => {
        console.log('fail ' + res);
      }
    });
  }, []);// eslint-disable-line react-hooks/exhaustive-deps


  /** 初始化消息时消息列表最后添加统计消息 */
  useEffect(() => {
    if (
      !dashboardMark
      && dashboardMessage
      && messages
      && messages.length > 0
    ) {
      let _messages = [...messages, dashboardMessage];
      setMessages(_messages);
      setDashboardMark(true);
      scrollToBottom();
    }
  }, [dashboardMessage, messages]);// eslint-disable-line react-hooks/exhaustive-deps


  /**
   * 获取channel列表
   * @param {*} user_id 
   * @param {*} callback 
   */
  const getChannels = async (user_id, callback) => {
    let channels = await getChannelDatas(user_id);
    // console.log(channels);
    setChannels(channels);
    callback && callback(channels);
  }


  /**
   * 点击菜单列表事件，设置当前channel，并设置未读消息数量为0
   * @param {*} channel 
   */
  const setChannel = (channel) => {
    setContentEditable('false');// 切换 channel 之前先关闭输入框
    let channel_url = channel.channel_url;

    //假如点击当前被激活的channel，则什么都不做
    if (channel_url === currentChannel.channel_url) return false;

    let _channel = {
      channel_url
    };
    let channelEncrypted = encrypt(JSON.stringify(_channel));

    apiReqs.getCT({//数据库中获取CT编号，用于推送google消息
      data: {
        channel: channelEncrypted
      },
      success: async (res) => {
        // if (module === 'admin') {
        //   console.log('[set_channel:success]res: ', res);
        //   console.log('[set_channel:success]current channel: ', channel);
        // }
        //消息输入框的内容替换为草稿箱里的内容
        let draftContent = channelDraft[channel.channel_url] || '';
        document.querySelector('#clipInput').innerHTML = draftContent;

        let _userInfo = userInfo;

        if (res.hasOwnProperty('ad_user_all')) {
          _userInfo.chatinfo.ad_user_all = res.ad_user_all;
        }

        if (res.hasOwnProperty('mb_id')) {
          _userInfo.chatinfo.mb_id = res.mb_id;
        }

        //重新设置state中的用户信息
        setUserInfo(_userInfo);

        // console.log(res);
        if (res.c_id !== '') {
          setChatId(res.c_id);
        }

        await getMessages({
          init: true,
          time:Date.now(),
          prev_limit: limitRef.current,
          next_limit: 0,
          channel: channel
        });

        setHasMoreMessages(true);
        setCurrentChannel(channel);
        setUnreadMsg(channel, 0);

        scrollToBottom();
      },
      fail: (res) => {
        // if (module === 'admin') {
        //   console.log('[set_channel:fail]res: ', res);
        //   console.log('[set_channel:fail]current channel: ', channel);
        // }
        console.log('fail ' + res);
      }
    });
  }


  const setChannelName = (name) => {
    let _currentChannel = currentChannel;
    _currentChannel.name = name;
    setCurrentChannel(_currentChannel);
  }


  /**
   * 设置channel列表中的未读消息数量，传入num参数则所有消息设置为已读，否则未读消息+1
   * @param {*} channel 
   * @param {*} num 
   */
  const setUnreadMsg = (channel, num) => {
    let _channelUrl = channel.channel_url || channel.url;

    for (let i = 0; i < channels.length; i++) {
      if (channels[i].channel_url !== _channelUrl) continue;

      if (num === undefined) {
        channels[i].unread_message_count <= 99 && channels[i].unread_message_count++;
      } else {
        channels[i].unread_message_count = 0;

        let users = module === 'admin' ? userInfo.chatinfo.ad_user_all : [userId];

        setMsgAsRead(_channelUrl, users);
      }

      setChannels(channels);
      break;
    }
  }


  /**
   * 获取消息列表（初始化/加载更多）
   * @param {*} data 
   * @returns 
   */
  const getMessages = async (data) => {
    //初始化则使用当前时间戳，加载更多时使用上一次获取的消息列表中第一条消息时间
    let message_ts = data.time ? data.time : messageTime;
    const response = await getMessageDatas(data, message_ts);
    let _messages = response.data.messages;
    // console.log(_messages);
    let msgData = [];

    if (_messages.length < data.prev_limit) {
      setHasMoreMessages(false);

      if (module !== 'admin' && (!messages || messages.length === 0)) {//用户携带初始化消息
        let initMessage = {
          created_at: 0,
          custom_type: 'initMessage',
          message_id: 0,
          user: {
            user_id: 'bot',
          }
        };

        setMessages([initMessage]);
        msgData.push(initMessage);
      }
    }

    if (_messages.length === 0) {
      if (module === 'admin' && (messages === null || data.init)) {//管理员不带初始化消息
        setMessages([]);
      }
      return false;
    }

    //重新组合message数据
    for (let i = 0; i < _messages.length; i++) {
      //标记第一条消息的id；加载更多后，滚动到这个位置
      if (i === 0) setFirstMessageId('msg-' + _messages[i].message_id);
      //file类型 且有引用消息
      let data;
      if (_messages[i].data == undefined && _messages[i].hasOwnProperty('file') && _messages[i].file.data ){
           data = _messages[i].file.data;
      }else{
           data = _messages[i].data;
      }

      msgData.push({
        channel_type: _messages[i].channel_type,
        channel_url: _messages[i].channel_url,
        created_at: _messages[i].created_at,
        custom_type: _messages[i].custom_type,
        data: data,
        file: _messages[i].file,
        message: _messages[i].message,
        message_id: _messages[i].message_id,
        type: _messages[i].type,
        updated_at: _messages[i].updated_at,
        mask_show:false,
        //item数据中 type为ADMM[具体类型尚不明确]时没有关于user的属性
        user: {
          is_active: _messages[i].user ? _messages[i].user.is_active : false,
          nickname: _messages[i].user ? _messages[i].user.nickname : '',
          profile_url: _messages[i].user ? _messages[i].user.profile_url : '',
          user_id: _messages[i].user ? _messages[i].user.user_id : '',
        }
      });
    }

    let msgGroup;
    if (data.init) {//初始化
      msgGroup = [...msgData];
      yyMsgRef.current = [...msgData];
    } else {//加载更多
      msgGroup = [...msgData, ...messages];
      yyMsgRef.current = [...msgData,...yyMsgRef.current];
    }
    if (!yyMessageIdRef.current){
      setMessages(msgGroup);
    }
    setMessageTime(msgData[0].created_at - 1);//设置第一条消息时间
    lastYytimeRef.current = msgData[0].created_at - 1; //设置获取引用消息 第一条消息时间
    data.init && scrollToBottom('instant');//初始化消息时，滚动到最底部
  }


  /**
   * 滚动条滚到顶部时加载更多消息
   * @param {*} scrollTop 
   */
  const getMoreMessage = async (scrollTop) => {
      const curYyDiv = document.getElementById(curMessageIdRef.current);
      if (curYyDiv){
          toggleGoToQuoteButton();
      }
    if (scrollTop === 0) {
      if (!hasMoreMessage) return false;//没有数据则不再触发滚动事件

      setMessageLoading(true);
      await getMessages({
        prev_limit: limitRef.current,
        next_limit: 0,
        channel: currentChannel
      });

      setTimeout(() => {
        setMessageLoading(false);

        //数据加载完成后，滚动条滚到上一次消息列表中的第一项（不这么做滚动条会留在顶部，看不出刚刚加载的消息）
        let _firstMessage = document.getElementById(firstMessageId);

        if (_firstMessage) {
          _firstMessage.scrollIntoView();
          loadImage(() => {//图片加载完成后再次调整滚动条
            _firstMessage.scrollIntoView();
          });
        }
      }, 100);
    }
  }

  /**
   * 点击引用消息后 加载更多消息
   *
   */

  const getMoreYyMsg = async (created_at,callback)=>{
    await getMessages({
      time:lastYytimeRef.current,
      prev_limit: yyLimitRef.current,
      next_limit: 0,
      channel: currentChannel
    });

    if (parseInt(lastYytimeRef.current) > parseInt(created_at)){
     await getMoreYyMsg(created_at,callback);
    }else {
      callback && callback();
    }
  }
//得要引用消息后 跳到引用消息处
  useEffect(()=>{
    if (yyMessageIdRef.current){
      scrollToYyMsg();
    }
  },[messages])


  useEffect(() => {
    if (yyMessage){
      clipInputRef.current.focus();
      clipInputRef.current.scrollIntoView()
    }
  },[yyMessage])

  /**
   * 滚动条滚动到引用消息的地方
   */

  const scrollToYyMsg = () => {

    let _yyMessage = document.getElementById(yyMessageIdRef.current);
    if (_yyMessage) {
      _yyMessage.scrollIntoView();
      let yyMsgId = yyMessageIdRef.current.split('-')[1];
      setTimeout(()=>{
          setMessages((msgs)=>{
             let yyMsgObj = msgs.find((item)=>{
                return  item.message_id == yyMsgId
              })
              yyMsgObj.mask_show = false;
             return [...msgs]
          })
      },1000)
    }
    quoteDisRef.current = true;
    yyMessageIdRef.current = null;
  }

  //页面滚动到 开始点击引用消息的位置

  const goToQuote = ()=>{
    const curYyDiv = document.getElementById(curMessageIdRef.current);
    if (curYyDiv){
      curYyDiv.scrollIntoView();
      setQuoteBtnView(false);
      curMessageIdRef.current = null;
      quoteDisRef.current = false;
    }
  }

  //显示与隐藏 回到引用位置div
  const toggleGoToQuoteButton = ()=>{
    const curYyDiv = document.getElementById(curMessageIdRef.current);
    if (curYyDiv && quoteDisRef.current){
      const chatBox = document.getElementById('chat-board');
      const scrollTop = chatBox.scrollTop;

      if (parseInt(curYyDiv.offsetTop) - parseInt(scrollTop) <= parseInt(chatBox.clientHeight)) {
        setQuoteBtnView(false);
        curMessageIdRef.current = null;
        quoteDisRef.current = false;
      }else {
        setQuoteBtnView(true);
      }
    }

  }

  /**
   * 滚动条滚动到底部
   */
  const scrollToBottom = (behavior = "smooth") => {
    setTimeout(() => {
      messagesEndRef.current && messagesEndRef.current.scrollIntoView({ behavior: behavior });
      loadImage(() => {//图片加载完成后继续滚动一次
        messagesEndRef.current && messagesEndRef.current.scrollIntoView({ behavior: behavior });
      });
    }, 100);
  }


  /** 接收新消息后，修改消息列表，并滚动条滚动到底部 */
  useEffect(() => {
    if (JSON.stringify(newMessage) !== '{}') {//不判断则以下代码执行两次
      const msgs = [...messages, newMessage];

      setMessages(msgs);
      setNewMessage({});

      scrollToBottom();
    }
  }, [newMessage]);// eslint-disable-line react-hooks/exhaustive-deps


  /** 对于最后一条消息进行已读状态处理 */
  useEffect(() => {
    if (lastMessage && lastMessageChannel) {
      let readerMap = {};
      let readerUserIds = [];
      let readers = lastMessageChannel.getReadMembers(lastMessage);

      for (let i = 0; i < readers.length; i++) {
        if (module === 'admin') {
          if (readers[i].userId === userInfo.chatinfo.mb_id) {
            readerUserIds.push(readers[i].nickname);
            break;
          }
        } else {
          if (userInfo.chatinfo.ad_user_all.includes(readers[i].userId)) {
            readerUserIds.push(readers[i].nickname);
            break;
          }
        }
      }

      readerMap[lastMessage.messageId] = readerUserIds;
      setMessageReaderMap(readerMap);
    }
  }, [lastMessage, lastMessageChannel, readerStateTrigger]);// eslint-disable-line react-hooks/exhaustive-deps


  /** 聊天窗中删除新消息 */
  useEffect(() => {
    if (delMessage) {//delMessage为消息id int
      setMessages(messages.filter(item => item.message_id !== delMessage));
      setDelMessage(0);
    }
  }, [delMessage]);// eslint-disable-line react-hooks/exhaustive-deps


  /** 接受消息后，channel对象里的last_message更新 */
  useEffect(() => {
    if (JSON.stringify(updatedChannel) !== '{}') {//不判断则以下代码执行两次
      let _channel;
      let _index;

      for (let i = 0; i < channels.length; i++) {
        if (channels[i].channel_url === updatedChannel.url) {
          channels[i].last_message = {
            created_at: updatedChannel.lastMessage.createdAt,
            type: updatedChannel.lastMessage.customType,
            custom_type: updatedChannel.lastMessage.customType,
            data: updatedChannel.lastMessage.data,
            message: updatedChannel.lastMessage.message,
            file: {
              url: updatedChannel.lastMessage.url
            }
          }

          _channel = channels[i];
          _index = i;
          break;
        }
      }

      if (_channel === undefined && _index === undefined) {//假如没有对应的channel，则新创建一个并添加到列表头部
        _channel = {
          channel_url: updatedChannel.url,
          cover_url: updatedChannel.coverUrl,
          created_at: updatedChannel.createdAt,
          joined_member_count: updatedChannel.joinedMemberCount,
          last_message: {
            created_at: updatedChannel.lastMessage.createdAt,
            type: updatedChannel.lastMessage.customType,
            custom_type: updatedChannel.lastMessage.customType,
            data: updatedChannel.lastMessage.data,
            message: updatedChannel.lastMessage.message,
            file: {
              url: updatedChannel.lastMessage.url
            }
          },
          member_count: updatedChannel.memberCount,
          members: updatedChannel.members,
          name: updatedChannel.name,
          unread_message_count: updatedChannel.unreadMessageCount
        }
      } else {
        channels.splice(_index, 1);
      }

      channels.unshift(_channel);
      setChannels(channels);
      setUpdatedChannel({});
    }
  }, [updatedChannel]);// eslint-disable-line react-hooks/exhaustive-deps


  /** 监听 sendbird 消息发送 */
  useEffect(() => {
    if (JSON.stringify(currentChannel) !== '{}') {//不判断则以下代码执行两次
      //首先清除已有的socket句柄
      sb.removeChannelHandler(channelUUID);

      let msgDeliveredTime = 0;//用于防止重复自动回复消息
      let msgExpire = 8000;//8秒内不再发送自动回复消息

      //构建历史消息已读状态map
      sb.GroupChannel.getChannel(currentChannel.channel_url, function(groupChannel, error) {
        if (error) return false;
    
        let listQuery = groupChannel.createPreviousMessageListQuery();
        
        // Retrieving previous messages.
        listQuery.load(function(messages, error) {
          if (error) return false;
          
          let readerMap = {};
          // Retrieving previous messages.
          messages.forEach(message => {
            let readerUserIds = [];
            const readers = groupChannel.getReadMembers(message);

            for (let i = 0; i < readers.length; i++) {
              if (module === 'admin') {
                if (readers[i].userId === userInfo.chatinfo.mb_id) {
                  readerUserIds.push(readers[i].nickname);
                  break;
                }
              } else {
                if (userInfo.chatinfo.ad_user_all.includes(readers[i].userId)) {
                  readerUserIds.push(readers[i].nickname);
                  break;
                }
              }
            }

            readerMap[message.messageId] = readerUserIds;
          });

          setMessageReaderMap(readerMap);
        });
      });

      channelHandler.onMessageReceived = (channel, message) => {
        // if (module === 'admin') {
        //   console.log('[socket]current channel: ', currentChannel.channel_url);
        //   console.log('[socket]sender channel: ', channel.url);
        //   console.log('[socket]message: ', message);
        // }

        if (channel.url === currentChannel.channel_url) {
          //先设置last_message，用于标记已读消息状态
          setLastMessage(message);

          //组合message数据
          let msgData = {
            channel_type: message.channelType,
            channel_url: message.channelUrl,
            created_at: message.createdAt,
            custom_type: message.customType,
            data: message.data,
            file: {
              name: message.name,
              url: message.plainUrl
            },
            message: message.message,
            message_id: message.messageId,
            type: message.type,
            updated_at: message.updatedAt,
            mask_show:false,
            user: {
              is_active: true,
              nickname: message._sender.nickname,
              profile_url: message._sender.plainProfileUrl,
              user_id: message._sender.userId
            }
          }

          setNewMessage(msgData);
          setUnreadMsg(channel, 0);//未读消息设置为0
        } else {
          setUnreadMsg(channel);//未读消息+1
        }

        setUpdatedChannel(channel);//更新channel 列表中的last_message
      }

      channelHandler.onDeliveryReceiptUpdated = (channel) => {
        let timeNow = Date.now();
        if (msgDeliveredTime + msgExpire > timeNow) return false;

        msgDeliveredTime = timeNow;

        let senderId = channel.lastMessage && channel.lastMessage._sender.userId;

        if (senderId !== userId) return false;

        let numberGroup = ['주문번호', '문의번호', '구매번호', '신청번호'];
        let contentGroup = ['주문내역', '문의내역', '구매내역', '신청내역'];

        if (numberGroup.includes(channel.lastMessage.message)) {
          if (leavingInfo.hasLeavingInfo === 'Y') {
            let dataToSend = {
              consultLeavingId: {
                leavingId: leavingInfo.leaving.li_id,
                leavingLevel: leavingInfo.leaving.li_level
              }
            };
            sendBotMessage({
              message: '해당문의에 관한 "문의번호" 입니다.',
              channel_url: channel.lastMessage.channelUrl,
              custom_type: 'consultLeavingId',
              data: JSON.stringify(dataToSend)
            });
          } else {
            sendBotMessage({
              message: '"문의번호"가 존재하지 않습니다.',
              channel_url: channel.lastMessage.channelUrl,
              custom_type: 'auto_answer_introduction',
              data: ''
            });
          }
        }

        if (contentGroup.includes(channel.lastMessage.message)) {
          apiReqs.getLeavingInfo({
            data: {
              chatid: chatIdEncrypted
            },
            success: (res) => {
              const leaving = JSON.parse(decrypt(res.leavinginfo));
              let dataToSend = {
                consultLeavingOverview: {
                  leavingOverview: leaving.leavingOverview
                }
              };
              sendBotMessage({
                message: '최근 3개월에 문의내역입니다.',
                channel_url: channel.lastMessage.channelUrl,
                custom_type: 'consultLeavingOverview',
                data: JSON.stringify(dataToSend)
              });
            }
          });
        }

        //주문내역은 push 보내지 않습니다.
        if (channel.lastMessage.customType === 'consultLeavingOverview') return;

        let pushMsg = '[이지챗] ';
        switch (channel.lastMessage.customType) {
          case 'attachmentFilename': pushMsg += '파일 메세지를 받았습니다.'; break;
          case 'clipboardImages': pushMsg += '이미지 메세지를 받았습니다.'; break;
          case 'consultLeavingId': pushMsg += '고객문의 메세지를 받았습니다.'; break;
          case 'consultLeavingProduct': pushMsg += '고객문의 메세지를 받았습니다.'; break;
          default: pushMsg += channel.lastMessage.message; break;
        }

        if (chatId === '') return;
        let chatInfoData = {
          chatId: chatId,
          isChatAdmin: module === 'admin',
          pushMsg: pushMsg,
          name: channel.name
        };
        chatInfoData = encrypt(JSON.stringify(chatInfoData));
        apiReqs.updateChatinfo({
          data: {
            chatid: chatInfoData
          },
          success: (res) => {
            //console.log(res)
          },
          fail: (res) => { console.log(res) }
        });
      }

      channelHandler.onReadReceiptUpdated = (groupChannel) => {
        if (currentChannel.channel_url !== groupChannel.url) return false;

        setLastMessageChannel(groupChannel);
        setReaderStateTrigger(Date.now());
      }

      channelHandler.onMessageUpdated = (channel, message) => {
        //console.log('message updated');
        //console.log(channel);
        //console.log(message);
      }

      channelHandler.onMessageDeleted = (channel, messageId) => {
        setDelMessage(messageId);
      }

      let _channelUUID = generateUUID();
      setChannelUUID(_channelUUID);
      sb.addChannelHandler(_channelUUID, channelHandler);

      setContentEditable('true');//socket handler设置好后激活输入框
    }
  }, [currentChannel]);// eslint-disable-line react-hooks/exhaustive-deps


  /**
   * 监听消息已读状态映射表的变化 / custom_type 为 'initMessage'则直接渲染
   */
  useEffect(() => {
    if (messages) {
      let _message = [];
      for (let i = 0; i < messages.length; i++) {
        if (messageReaderMap.hasOwnProperty(messages[i].message_id) || messages[i].custom_type === 'initMessage') {
          messages[i].readers = messageReaderMap[messages[i].message_id];
        }
        _message.push(messages[i]);
      }

      setMessages(_message);
    }
  }, [messageReaderMap]);// eslint-disable-line react-hooks/exhaustive-deps


  /**
   * 对话内容监听点击鼠标右键 移入 移除的事件
   */
  useEffect(() => {
    // 右键事件监听
    const bubbles = document.querySelectorAll(".bubble");

    if (bubbles && bubbles.length) {
      bubbles.forEach((item) => {

        let binded = item.getAttribute('binded');

        if (binded === '') {//如果已绑定 contextmenu事件，则不再操作
          item.addEventListener("contextmenu", (e) => {
            handleContextmenu(e,item);
          });
          const parentDiv = item.parentNode;
          const nextSiblingDiv = item.nextElementSibling.querySelectorAll('.msg-iconfont')[0];
          parentDiv.addEventListener("mouseover", (e) => {
            if (nextSiblingDiv && nextSiblingDiv.tagName === 'DIV'){
              nextSiblingDiv.style.visibility = "visible"
              const create_time = item.getAttribute('created_at');
              const isWithdraw = parseInt(create_time) + expired > Date.now();
              if (item.classList.contains('me') && isWithdraw){
                nextSiblingDiv.querySelectorAll('.msg-withdraw')[0].style.display = "block";
              }
            }
          });
          parentDiv.addEventListener("mouseout", (e) => {
            if (nextSiblingDiv && nextSiblingDiv.tagName === 'DIV'){
              nextSiblingDiv.style.visibility = "hidden"
            }
          });

          item.setAttribute('binded', 'binded');
        }
      });
    }
  }, [messages]);

  const handleContextmenu = (e,item) => {
    e.preventDefault();//关闭默认鼠标右键事件

    let bubbleId = item.id;//格式为 bubble-1234567890，用于获取 message_id
    const messageId = bubbleId.split('-')[1];

    const x = e.clientX
    const y = e.clientY
    // 弹窗节点

    const ctxMenu = document.querySelector(".context-menu");

    ctxMenu.style.left = x + "px"; //设置弹窗位置
    ctxMenu.style.top = y + "px";
    ctxMenu.style.display = "block";

    //设置 message_id 和 message 创建时间，用于传递到 ContextMenu 组件
    setContextMessageId(messageId);
    setContextMessageCreatedAt(item.getAttribute('created_at'));

    //只重写我的消息的右键功能
    if (item.classList.contains('me')) {
      setContextMessageDraw(true);
    }else{
      setContextMessageDraw(false);
    }

    //对话泡泡上点击右键时选中对话内容
    const selection = window.getSelection();
    selection.removeAllRanges();

    const childElements = item.children;
    // console.log(childElements);
    //复制的时候 排除引用的元素
    for (const element of childElements) {
      if (element.classList.contains('yydis')){
        continue;
      }
      const range = document.createRange();
      range.selectNodeContents(element); // 需要选中的dom节点
      selection.addRange(range);
    }


    document.onclick = function () { //点击其他区域要隐藏弹窗
      ctxMenu.style.display = "none";
    }
  }

  //鼠标移入后 显示选中btn 点击处理
  const selectContent = (message_id) => {
    const curDiv = document.getElementById('bubble-' + message_id);

    //对话泡泡上点击右键时选中对话内容
    const selection = window.getSelection();
    selection.removeAllRanges();

    const childElements = curDiv.children;
    // console.log(childElements);
    //复制的时候 排除引用的元素
    for (const element of childElements) {
      if (element.classList.contains('yydis')){
        continue;
      }
      const range = document.createRange();
      range.selectNodeContents(element); // 需要选中的dom节点
      selection.addRange(range);
      document.execCommand("Copy");
    }
  }


  //引用消息点击事件回调
  const handleYyMsg = async (yy_id,creat_time,ms_id) => {
      // e.preventDefault();//关闭默认鼠标右键事件
      yyMessageIdRef.current = 'msg-' + yy_id;
      curMessageIdRef.current = 'msg-' + ms_id;
      let yymsg = messages.find((ele)=>{
          return ele.message_id == yy_id
      })
      if(yymsg){
          yymsg.mask_show = true;
          setMessages([...messages]);
          scrollToYyMsg();
      }else {
          if (lastYytimeRef.current > parseInt(creat_time)){
            setYyMessageLoading(true);
              await getMoreYyMsg(creat_time,()=>{
                  yyMsgRef.current.forEach((ele)=>{
                      if (ele.message_id == yy_id) {
                          ele.mask_show = true;
                      }
                  })
                  setYyMessageLoading(false);
                  setMessages([...yyMsgRef.current]);
              })
          }
      }
  }


  /** 管理员打开聊天窗口时以 1/10 的概率清理最后发送消息时长为 180 天以上的 group_channel */
  useEffect(() => {
    if (module === 'admin' && channels.length > 0) {
      let randNum = Math.floor(Math.random() * 10);
      if (randNum === 0) {
        let expire_day = 180;
        let expiredMiSec = Date.now() - expire_day * 86400 * 1000;
        let expiredSec = Math.floor(Date.now() / 1000 - expire_day * 86400);
        let expiredChannels = channels.filter(item => {
          if (item.last_message) {
            return item.last_message.created_at < expiredMiSec;
          } else {
            return item.created_at < expiredSec;
          }
        });

        // console.log(expiredChannels);

        for (let i = 0; i < expiredChannels.length; i++) {
          deleteChannel(expiredChannels[i].channel_url, () => {
            let channelToSend = {
              channelUrl: expiredChannels[i].channel_url
            };

            let channelEncrypted = encrypt(JSON.stringify(channelToSend));

            apiReqs.deleteChannel({
              data: {
                channelEncrypted: channelEncrypted
              },
              success: (res) => {
                // console.log(res);
              },
              fail: (res) => {
                console.log(res);
              }
            });
          });
        }
      }
    }
  }, [channels]);// eslint-disable-line react-hooks/exhaustive-deps


  /**
   * 递归发送消息
   * @param {*} channel_url 
   * @param {*} msgs 
   * @param {*} callback 
   */
  const recursiveSendMessage = (channel_url, msgs, yy_obj, callback) => {
    if (msgs.length === 0) {
      callback();
    } else {
      let msg = msgs.shift();
      let msgData = {};
      if(Object.keys(yy_obj).length > 0) {
        msgData.data = JSON.stringify({ yyInfo : yy_obj});
      }
      if (msg['type'] === 'text') {//文字消息发送
        msgData.message = msg['value'];
        msgData.message_type = 'MESG';
        msgData.user_id = userId;
        msgData.custom_type = 'msg';

        sendMessageHandle(channel_url, msgData, () => {
          recursiveSendMessage(channel_url, msgs, yy_obj, callback);
        });
      } else if (msg['type'] === 'image') {//输入框图片消息发送
        let imgSrc = msg['value'];

        let user_id_obj = {
          id: userId
        };
        let userIdEncrypted = encrypt(JSON.stringify(user_id_obj));

        apiReqs.uploadFile({
          data: {
            file: imgSrc,
            userid: userIdEncrypted
          },
          success: (res) => {
            if (res.state === 0) {
              msgData.message = '';
              msgData.message_type = 'MESG';
              msgData.user_id = userId;
              msgData.custom_type = 'clipboardImages';
              if (msgData.data !== undefined){
                let yyObj = JSON.parse(msgData.data);
                msgData.data = JSON.stringify({ ...yyObj, clipboardImages: [res.url] });
              }else {
                msgData.data = JSON.stringify({ clipboardImages: [res.url] });
              }

              sendMessageHandle(currentChannel.channel_url, msgData, () => {
                setTimeout(()=>{//图片处理后等待400毫秒再处理下一个信息
                  recursiveSendMessage(channel_url, msgs, yy_obj, callback);
                }, 400);
              });
            } else {
              recursiveSendMessage(channel_url, msgs, yy_obj, callback);
            }
          },
          fail: (err) => {
            recursiveSendMessage(channel_url, msgs, yy_obj, callback);
          }
        });
      } else if (msg['type'] === 'file') {//文件消息发送
        let file = msg['value'];

        if (!allowedMIME.includes(file.type) || file.size === 0) {
          recursiveSendMessage(channel_url, msgs, yy_obj, callback);
        } else {
          let reader = new FileReader();
          reader.readAsDataURL(file);

          reader.onload = () => {
            let resStr = reader.result;

            let fileName = file.name;
            let fileType = file.type;

            let user_id_obj = {
              id: userId
            };
            let userIdEncrypted = encrypt(JSON.stringify(user_id_obj));

            apiReqs.uploadFile({
              data: {
                file: resStr,
                userid: userIdEncrypted
              },
              success: (res) => {
                if (res.state === 0) {
                  let imgUrl = res.url;
                  msgData.message = imgUrl;
                  msgData.message_type = 'FILE';
                  msgData.user_id = userId;
                  msgData.custom_type = 'attachmentFilename';
                  msgData.url = imgUrl;
                  msgData.file_name = fileName;
                  msgData.file_type = fileType;
                  sendMessageHandle(currentChannel.channel_url, msgData, () => {
                    setTimeout(()=>{//文件处理后等待400毫秒再处理下一个信息
                      recursiveSendMessage(channel_url, msgs, yy_obj, callback);
                    }, 800);
                  });
                } else {
                  recursiveSendMessage(channel_url, msgs, yy_obj, callback);
                }
              },
              fail: (err) => {
                recursiveSendMessage(channel_url, msgs, yy_obj, callback);
              },
              done: () => {}
            });
          }

          reader.onerror = (error) => {
            recursiveSendMessage(channel_url, msgs, yy_obj, callback);
          };
        }
      }
    }
  }


  /**
   * 发送基本消息（包含clip image）
   * @returns 
   */
  const sendMessage = () => {
    if (messageSending) return false;
    let clipInput = document.querySelector('#clipInput');
    let msg = messageFormat(clipInput.innerHTML).trim();

    if (msg === '') return false;

    let yy_obj = {};
    if(yyMessage){
      const name = yyMessage.user.user_id !== userId ? yyMessage.user.user_id : currentChannel.name;
      yy_obj.custom_type = yyMessage.custom_type;
      yy_obj.created_at = yyMessage.created_at;
      yy_obj.message_id = yyMessage.message_id;
      yy_obj.name = name;
      yy_obj.message = yyMessage.message;
      if (yyMessage.custom_type === 'attachmentFilename'){
        yy_obj.plainUrl = yyMessage.file.url;
        yy_obj.fileName = yyMessage.file.name;
      } else{
        yy_obj.data = yyMessage.hasOwnProperty('data') ? yyMessage.data : '';
      }
    }

    setMessageSending(true);
    let msgsData = messageToArray(msg);
    //队列模式发送消息
    recursiveSendMessage(currentChannel.channel_url, msgsData, yy_obj, async () => {
      setMessageSending(false);
      if (yyMessage){
        setYyMessage(null);
      }
      //清空输入框
      clearInput();
      
      if (currentChannel.custom_type === 'end' || currentChannel.custom_type === '') {
        const res = await updateChannel(currentChannel.channel_url, {custom_type: 'wait'});
        if (!res || !res.code) {
          setCurrentChannel({...currentChannel, custom_type: 'wait'});
        }
      }
    });
  }


  /** 清空输入框 */
  const clearInput = () => {
    document.querySelector('#clipInput').innerHTML = "";

    if (module === 'admin') {
      let _key = currentChannel.channel_url;
      let _channelDraft = {...channelDraft};
      _channelDraft[_key] = '';
      setChannelDraft(_channelDraft);
    }
  }


  /**
   * 发送本地图片
   */
  const sendLocalFile = async (e) => {
    let files = e.target.files;

    setMessageSending(true);
    let fileDatas = [];

    for (let i = 0; i < files.length; i++) {
      if (!allowedMIME.includes(files[i].type)) {
        continue;
      }

      let _obj = {
        type: 'file',
        value: files[i],
      }

      fileDatas.push(_obj);
    }

    if (fileDatas.length === 0) {
      setMessageSending(false);
      return false;
    }

    let yy_obj = {};
    if(yyMessage){
      const name = yyMessage.user.user_id !== userId ? yyMessage.user.user_id : currentChannel.name;
      yy_obj.custom_type = yyMessage.custom_type;
      yy_obj.created_at = yyMessage.created_at;
      yy_obj.message_id = yyMessage.message_id;
      yy_obj.name = name;
      yy_obj.message = yyMessage.message;
      if (yyMessage.custom_type === 'attachmentFilename'){
        yy_obj.plainUrl = yyMessage.file.url;
        yy_obj.fileName = yyMessage.file.name;
      } else{
        yy_obj.data = yyMessage.hasOwnProperty('data') ? yyMessage.data : '';
      }
    }

    //队列模式发送消息
    recursiveSendMessage(currentChannel.channel_url, fileDatas, yy_obj, async () => {
      setMessageSending(false);
      if (yyMessage){
        setYyMessage(null);
      }
      if (currentChannel.custom_type === 'end' || currentChannel.custom_type === '') {
        const res = await updateChannel(currentChannel.channel_url, {custom_type: 'wait'});
        if (!res || !res.code) {
          setCurrentChannel({...currentChannel, custom_type: 'wait'});
        }
      }
    });

    //清空文件选择表单
    e.target.value = '';
  }
//点击引用后 输入框显示引用元素
  const handleMesssageObj = (ms_id)=>{
    let curMess = messages.find(item => item.message_id == ms_id);
    setYyMessage({ ...curMess});
  }

  /** 发送自动回复消息 */
  const sendAutoReplyMsg = (e) => {
    let text = e.target.innerHTML;
    recursiveSendMessage(currentChannel.channel_url, [{type: 'text', value: text}], {}, ()=>{});
  }


  /** 消息输入框中输入（粘贴）内容时，把内容添加到草稿箱 */
  const setDraft = (e) => {
    if (module !== 'admin') return false;

    let _key = currentChannel.channel_url;
    let _val = e.target.innerHTML;
    let _channelDraft = {...channelDraft};
    _channelDraft[_key] = _val;
    setChannelDraft(_channelDraft);
  }


  /**
   * 键盘事件，设置 shift+enter为换行，enter为发送消息
   * @param {*} e 
   */
  const _handleKeyDown = (e) => {
    let cursorPosition = getCursorPosition(e.target);
    // console.log(cursorPosition);
    if (e.key === 'Backspace' && cursorPosition === 0) {
      setYyMessage(null);
      return;
    }

    if (!e.shiftKey && e.key === 'Enter') {
      sendMessage();
    }
  }
// 获取光标位置的函数
  function getCursorPosition(element) {
    let selection = window.getSelection();
    if (selection.rangeCount > 0) {
      let range = selection.getRangeAt(0);
      if (range.startContainer === element) {
        return range.startOffset;
      }
    }
    return -1;
  }

  /**
   * 引用消息类型组件中文件类型组件
   */
  const fileYyComponent = (item) => {
    let fileUrl = item.file.url;
    let fileData = fileUrl.split('/');
    let fileName = fileData[fileData.length - 1];
    let files = fileName.split('.');
    let fileExtension = files[files.length - 1].toLowerCase();
    if (allowedFiles.hasOwnProperty(fileExtension)) {
      return (
          <div className="yy_des">
              <img src={allowedFiles[fileExtension]} alt="" />
              <em>{item.file.name}</em>
          </div>
      );
    } else if (allowedVideo.includes(fileExtension)) {
      return (
          <div className="">
            <video width="30" controls>
              <source src={fileUrl} />
            </video>
          </div>
      );
    } else {
      return (
          <div className="">
              <img src={fileUrl} alt="" width="50px" />
          </div>
      );
    }
  }


  /**
   * 消息列表中 引用 文件类型组件
   */

  const fileMsgYyComponent = (item) => {
    let fileUrl = item.plainUrl || '';
    let fileData = fileUrl.split('/');
    let fileName = fileData[fileData.length - 1];
    let files = fileName.split('.');
    let fileExtension = files[files.length - 1].toLowerCase();
    if (allowedFiles.hasOwnProperty(fileExtension)) {
      return (
              <div className="yy_des">
                <img src={allowedFiles[fileExtension]} alt="" />
                <em>{item.fileName}</em>
              </div>
      );
    } else if (allowedVideo.includes(fileExtension)) {
      return (
            <video width="30" controls>
              <source src={fileUrl} />
            </video>
      );
    } else {
      return (
          <div>
              <img src={fileUrl} width="80px" alt="" />
          </div>
      );
    }
  }


  /**
   * 消息类型组件中文件类型组件
   * @param {*} item 
   * @returns 
   */
  const fileMessageComponent = (item, msgBelongTo, yyCompents, masklayer) => {
    let fileUrl = item.file.url;
    let fileData = fileUrl.split('/');
    let fileName = fileData[fileData.length - 1];
    let files = fileName.split('.');
    let fileExtension = files[files.length - 1].toLowerCase();
    if (allowedFiles.hasOwnProperty(fileExtension)) {
      return (
        <div className={`bubble bubble-file ${msgBelongTo}`} id={'bubble-' + item.message_id} binded="" created_at={item.created_at}>
          {yyCompents}
            {masklayer}
          <a href={fileUrl} target="_blank" rel="noreferrer">
            <img src={allowedFiles[fileExtension]} alt="" />
            <em>{item.file.name}</em>
          </a>
        </div>
      );
    } else if (allowedVideo.includes(fileExtension)) {
      return (
        <div className={`bubble bubble-file ${msgBelongTo}`} id={'bubble-' + item.message_id} binded="" created_at={item.created_at}>
          {yyCompents}
            {masklayer}
          <video width="184" controls>
            <source src={fileUrl} />
          </video>
        </div>
      );
    } else {
      return (
        <div className={`bubble bubble-img ${msgBelongTo}`} id={'bubble-' + item.message_id} binded="" created_at={item.created_at}>
          {yyCompents}
            {masklayer}
          <a href={fileUrl} target="_blank" rel="noreferrer">
            <img src={fileUrl} alt="" />
          </a>
        </div>
      );
    }
  }


  /**
   * 消息已读标记
   * @param {*} readers 
   * @returns 
   */
  const messageReaders = (item) => {
    let readers = item.readers;

    //没有已读会员数据或消息不是我发送的，则返回空字符串
    if (!readers || item.user.user_id !== userId) return '';

    let cls;
    let txt;
    let title = '';
    let style = {};

    //没有已读会员或不是最后一条信息，则返回空字符串
    if (readers.length === 0 || item.message_id !== messages[messages.length - 1].message_id) return '';

    if (module === 'admin') {
      cls = readers.length > 0 ? 'msg-readers active' : 'msg-readers';
      txt = readers.length > 0 ? '✔' : readers.length;
      title = readers.join('\n');
      style = { cursor: 'pointer' }
    } else {
      cls = 'msg-readers active';
      txt = '✔';
    }

    return <div className={cls} title={title} style={style}>{txt}</div>;
  }


  /**
   * 引用类型组件
   *
   *
   */
  const messageYyComponent = (item) => {
    // console.log(item)
    const name = item.user.user_id !== userId ? item.user.user_id : currentChannel.name;
    let _data;
    switch (item.custom_type) {
      case 'attachmentFilename'://파일 메세지
        return (
            <div className="yy" suppressContentEditableWarning contentEditable="false">
              <div className="yy_msg">
                <div className="yy_div">
                  <span className="yy_name" style={{whiteSpace: 'nowrap'}}>{name}</span><span>:</span>
                </div>
                {fileYyComponent(item)}
              </div>
              <div className="yy_img" onClick={() => {
                setYyMessage(null)
              }}>
                <img src={require("./Common/img/close.png")} alt=""/>
              </div>
            </div>
        );

      case 'clipboardImages'://캡처한 이미지 메세지
        let imageData = JSON.parse(item.data);
        let images = imageData.hasOwnProperty('clipboardImages') ? imageData.clipboardImages : imageData;

        const imgComp = images.map((item, index)=>{
           return <img key={index} src={item} width="50px" alt="" />;
        });
        return (
            <div className="yy" suppressContentEditableWarning contentEditable="false">
              <div className="yy_msg">
                <div className="yy_div">
                  <span className="yy_name" style={{whiteSpace: 'nowrap'}}>{name}</span><span>:</span>
                </div>
                {imgComp}
              </div>
              <div className="yy_img" onClick={() => {
                setYyMessage(null)
              }}>
                <img src={require("./Common/img/close.png")} alt=""/>
              </div>
            </div>
        );

      case 'consultLeavingId':
        return (
            <div className="yy" suppressContentEditableWarning contentEditable="false">
              <div className="yy_msg">
                <div>
                  <span className="yy_name" style={{whiteSpace: 'nowrap'}}>{name}</span><span>:</span>
                </div>
                <p className="yy_context">{item.message}</p>
              </div>
              <div className="yy_img" onClick={() => {
                setYyMessage(null)
              }}>
                <img src={require("./Common/img/close.png")} alt=""/>
              </div>
            </div>
        );

      case 'utis'://유통인쇼 메세지
        _data = JSON.parse(item.data);

        if (_data === '') return `<div key=${item.message_id}></div>`;

        return (
            <div className="yy" suppressContentEditableWarning contentEditable="false">
              <div className="yy_msg">
                <div className="yy_div">
                  <span className="yy_name" style={{whiteSpace: 'nowrap'}}>{name}</span><span>:</span>
                </div>
                <div className="yyutis">
                  <img src={_data.image} alt=""/>
                  <div className="msg-product-info">
                    <h4 title={_data.title}>{_data.title}</h4>
                    <section>
                      <div className="msg-product-price">
                        <strong>{_data.unit_price ? addComma(_data.unit_price) : ''}</strong>
                        {_data.unit_price ? <em>원</em> : ''}
                      </div>
                    </section>
                  </div>
                </div>
              </div>
              <div className="yy_img" onClick={() => {
                setYyMessage(null)
              }}>
                <img src={require("./Common/img/close.png")} alt=""/>
              </div>
            </div>
        );

      case 'consultLeavingOverview'://통계 메세지

        _data = JSON.parse(item.data);

        if (_data === '') return `<div key=${item.message_id}></div>`;

        let overviewData = _data.hasOwnProperty('consultLeavingOverview') && _data['consultLeavingOverview'].hasOwnProperty('leavingOverview') ? _data['consultLeavingOverview']['leavingOverview'] : {};


        return (
            <div className="yy" suppressContentEditableWarning contentEditable="false">
              <div className="yy_msg">
                <div className="yy_div">
                  <span className="yy_name" style={{whiteSpace: 'nowrap'}}>{name}</span><span>:</span>
                </div>
                <div className="yyutis">
                  {
                    module === 'admin'
                        ? <strong className="consult">문의내역</strong>
                        : <div className="overview">
                          <h3>{item.message}</h3>
                          <div className="overview-list">
                            <a href="#">
                              <span>견적대기</span>
                              <strong>{overviewData['AA']}</strong>
                            </a>
                            <a href="#">
                              <span>상품결제대기</span>
                              <strong>{overviewData['AC']}</strong>
                            </a>
                            <a href="#">
                              <span>입고대기</span>
                              <strong>{overviewData['BB']}</strong>
                            </a>
                            <a href="#">
                              <span>물류결제대기</span>
                              <strong>{overviewData['BE']}</strong>
                            </a>
                            <a href="#">
                              <span>출고완료</span>
                              <strong>{overviewData['BK']}</strong>
                            </a>
                            <a href="#">
                              <span>미완료신청건</span>
                              <strong>{overviewData['BA']}</strong>
                            </a>
                          </div>
                        </div>}
                </div>
              </div>
              <div className="yy_img" onClick={() => {
                setYyMessage(null)
              }}>
                <img src={require("./Common/img/close.png")} alt=""/>
              </div>
            </div>
        );


      case 'consultLeavingProduct'://상품정보 메세지
        _data = JSON.parse(item.data);

        if (_data === '') return <div key={item.message_id}></div>;

        let productData = _data.hasOwnProperty('consultLeavingProduct') ? _data['consultLeavingProduct'] : {};


        return (
            <div className="yy" suppressContentEditableWarning contentEditable="false">
              <div className="yy_msg">
                <div className="yy_div">
                  <span className="yy_name" style={{whiteSpace: 'nowrap'}}>{name}</span><span>:</span>
                </div>
                <div className="yyutis">
                  <img src={productData.productImage} alt=""/>
                  <div className="msg-product-info">
                    <h4 title={productData.productName}>{productData.productName}</h4>
                    <div className="msg-product-opt"
                         title={productData.productOption.replace('&gt;', '\\n')}>{productData.productOption}</div>
                    <section>
                      <div className="msg-product-price">
                        <strong>{productData.productPriceKr ? addComma(productData.productPriceKr) : ''}</strong>
                        {productData.productPriceKr ? <em>원</em> : ''}
                      </div>
                      <span>x {productData.productNum}</span>
                    </section>
                  </div>
                </div>
              </div>
              <div className="yy_img" onClick={() => {
                setYyMessage(null)
              }}>
                <img src={require("./Common/img/close.png")} alt=""/>
              </div>
            </div>
        );


      case 'initMessage'://안내 메세지
        return (
            <div className="yy" suppressContentEditableWarning contentEditable="false">
              <div className="yy_msg">
                <div className="yy_div">
                  <span className="yy_name" style={{whiteSpace: 'nowrap'}}>{name}</span><span>:</span>
                </div>
                <div className="msg-align">
                  <div className="msg-init">
                    <section>
                      안녕하세요, 1:1담당매니저서비스 이지챗입니다. 근무일 기준(주말, 공휴일제외) 오전 9시~오후 5시까지 상담가능합니다.
                      <br/><br/>
                      "문의내역"이라고 전송하면 최근 3개월 문의 진행건이 표시됩니다.
                    </section>
                  </div>
                </div>
              </div>
              <div className="yy_img" onClick={() => {
                setYyMessage(null)
              }}>
                <img src={require("./Common/img/close.png")} alt=""/>
              </div>
            </div>
        );


      default://일반 문자 메세지
        let msg = item.message || '';
        return (
            <div className="yy" suppressContentEditableWarning contentEditable="false">
            <div className="yy_msg">
                <div className="yy_div">
                  <span className="yy_name" style={{whiteSpace: 'nowrap'}}>{name}</span><span>님의&nbsp;메세지에 댓글 작성</span>
                </div>
                <div className="msg-align">
                  <strong className="basic-msg yydis_context"
                          dangerouslySetInnerHTML={{__html: msg.replace(/\n/g, '<br/>')}}></strong>
                </div>
              </div>
              <div className="yy_img" onClick={() => {
                setYyMessage(null)
              }}>
                <img src={require("./Common/img/close.png")} alt=""/>
              </div>
            </div>
        );
    }
  }


  /**
   * 消息类型组件
   * @param {*} item
   * @returns
   */
  const messageTypeComponent = (item) => {
    let _data;
    let msgPosition;
    let msgBelongTo;
    const masklayer = item.mask_show ? <div className="mask_layer"></div> : ''
    // console.log(item)
    if (!userInfo.chatinfo) return '';

    //跳过安卓APP发送的自动回复
    if (item.custom_type.indexOf('_android') !== -1) return '';

    if (module === 'admin') {
      // if (item.user.user_id === userId || item.user.user_id === userInfo.chatinfo.ad_com_code) {
      if (
        userInfo.chatinfo.ad_user_all.includes(item.user.user_id)
        || item.user.user_id.indexOf('sellchina') !== -1
        || item.user.user_id.indexOf('cninsider') !== -1
      ) {
        msgPosition = 'end';
        msgBelongTo = 'me';
      } else {
        msgPosition = 'start';
        msgBelongTo = 'you';
      }
    } else {
      if (item.user.user_id === userId) {
        msgPosition = 'end';
        msgBelongTo = 'me';
      } else {
        msgPosition = 'start';
        msgBelongTo = 'you';
      }
    }

    let profileUrl = null;
    if (item.user && item.user.profile_url !== '') {
      profileUrl = item.user.profile_url;
    }


    //引用组件
     let yyCompents = '';
    if (item.data !== '' && item.data !== undefined){
      let msg_data = JSON.parse(item.data);
      if (msg_data.hasOwnProperty('yyInfo')){
        let info = msg_data.yyInfo;
        switch (info.custom_type) {
            case 'attachmentFilename':
            yyCompents = (
                <div className="yydis" onClick={()=>{handleYyMsg(info.message_id,info.created_at,item.message_id)}} id={'yydis-' + info.message_id} created_at={info.created_at}>
                  <div className="yydis_div">
                  <span className="yydis_name" style={{ whiteSpace: 'nowrap' }} >{info.name}</span><span>:</span>
                  </div>
                  {fileMsgYyComponent(info)}
                </div>
            );
            break;
          case 'clipboardImages':
            let imgObj = typeof info.data === 'string' ? JSON.parse(info.data) : info.data;
            let images = imgObj.hasOwnProperty('clipboardImages') ? imgObj.clipboardImages : imgObj;
            yyCompents = (
                <div className="yydis" onClick={()=>{handleYyMsg(info.message_id,info.created_at,item.message_id)}} id={'yydis-' + info.message_id}  created_at={info.created_at}>
                  <div className="yydis_div">
                  <span className="yydis_name" style={{ whiteSpace: 'nowrap' }} >{info.name}</span><span>:</span>
                  </div>
                  {images.map((item, key_img) => {
                    return (
                          <img key={key_img} src={item} width="80px" alt="" />
                    );
                  })}
                </div>
            );
            break;

          case 'consultLeavingId'://문의 메세지
            _data = JSON.parse(info.data);

            // if (_data === '') return <div key={item.message_id} id={'msg-' + item.message_id}></div>;

            let adminUrl = getAdminUrl(_data['consultLeavingId']['leavingLevel'], _data['consultLeavingId']['leavingId']);
            yyCompents = (
                <div className="yydis" onClick={()=>{handleYyMsg(info.message_id,info.created_at,item.message_id)}} id={'yydis-' + info.message_id} created_at={info.created_at}>
                  <div className="yydis_div">
                    <span className="yydis_name" style={{ whiteSpace: 'nowrap' }} >{info.name}</span><span>:</span>
                  </div>
                  <div>
                      {
                        module === 'admin'
                            ? <a href={adminUrl} target="_blank" rel="noreferrer" className="consult">{info.message}</a>
                            : <strong className="consult">{info.message}</strong>
                      }
                  </div>
                </div>
            );
            break;

          case 'utis'://유통인쇼 메세지

            // console.log(info)
              if (info.data == '' || info.data == undefined) return ''
            _data = JSON.parse(info.data);

            // console.log(_data)
            // if (_data === '') return <div id={'yymsg-' + info.message_id}></div>;

            yyCompents = (
                <div className="yydis" onClick={()=>{handleYyMsg(info.message_id,info.created_at,item.message_id)}} id={'yydis-' + info.message_id} created_at={info.created_at}>
                  <div className="yydis_div">
                    <span className="yydis_name" style={{ whiteSpace: 'nowrap' }} >{info.name}</span><span>:</span>
                  </div>
                  <div className="yyutis">
                  <img src={_data.image} alt="" />
                  <div className="msg-product-info">
                    <h4 title={_data.title}>{_data.title}</h4>
                    <section>
                      <div className="msg-product-price">
                        <strong>{_data.unit_price ? addComma(_data.unit_price) : ''}</strong>
                        {_data.unit_price ? <em>원</em> : ''}
                      </div>
                      {/* <span>x {productData.productNum}</span> */}
                    </section>
                  </div>
                  </div>
                </div>
            );
            break;

          case 'consultLeavingOverview'://통계 메세지
            _data = JSON.parse(info.data);
            let overviewData = _data.hasOwnProperty('consultLeavingOverview') && _data['consultLeavingOverview'].hasOwnProperty('leavingOverview') ? _data['consultLeavingOverview']['leavingOverview'] : {};

            yyCompents = (
                <div className="yydis" onClick={()=>{handleYyMsg(info.message_id,info.created_at,item.message_id)}} id={'yydis-' + info.message_id} created_at={info.created_at}>
                  <div className="yydis_div">
                    <span className="yydis_name" style={{ whiteSpace: 'nowrap' }} >{info.name}</span><span>:</span>
                  </div>
                  <div className="msg-align">
                      {
                        module === 'admin'
                            ? <strong className="consult">문의내역</strong>
                            : (
                                <div className="overview">
                                  <h3>{info.message}</h3>
                                  <div className="overview-list">
                                    <a href="#">
                                      <span>견적대기</span>
                                      <strong>{overviewData['AA']}</strong>
                                    </a>
                                    <a href="#">
                                      <span>상품결제대기</span>
                                      <strong>{overviewData['AC']}</strong>
                                    </a>
                                    <a href="#">
                                      <span>입고대기</span>
                                      <strong>{overviewData['BB']}</strong>
                                    </a>
                                    <a href="#">
                                      <span>물류결제대기</span>
                                      <strong>{overviewData['BE']}</strong>
                                    </a>
                                    <a href="#">
                                      <span>출고완료</span>
                                      <strong>{overviewData['BK']}</strong>
                                    </a>
                                    <a href="#">
                                      <span>미완료신청건</span>
                                      <strong>{overviewData['BA']}</strong>
                                    </a>
                                  </div>
                                </div>
                            )}
                  </div>
                </div>
            );
            break;

          case 'consultLeavingProduct'://상품정보 메세지
            _data = JSON.parse(info.data);
            let productData = _data.hasOwnProperty('consultLeavingProduct') ? _data['consultLeavingProduct'] : {};

            yyCompents = (
                <div className="yydis" onClick={()=>{handleYyMsg(info.message_id,info.created_at,item.message_id)}} id={'yydis-' + info.message_id} created_at={info.created_at}>
                  <div className="yydis_div">
                    <span className="yydis_name" style={{ whiteSpace: 'nowrap' }} >{info.name}</span><span>:</span>
                  </div>
                  <div className="yyutis">
                    <img src={productData.productImage} alt="" />
                    <div className="msg-product-info">
                      <h4 title={productData.productName}>{productData.productName}</h4>
                      <div className="msg-product-opt" title={productData.productOption.replace('&gt;', '\n')}>{productData.productOption}</div>
                      <section>
                        <div className="msg-product-price">
                          <strong>{productData.productPriceKr ? addComma(productData.productPriceKr) : ''}</strong>
                          {productData.productPriceKr ? <em>원</em> : ''}
                        </div>
                        <span>x {productData.productNum}</span>
                      </section>
                    </div>
                  </div>
                </div>
            );
            break;

          case 'initMessage'://안내 메세지
            yyCompents = (
                <div className="yydis" onClick={()=>{handleYyMsg(info.message_id,info.created_at,item.message_id)}} id={'yydis-' + info.message_id} created_at={info.created_at}>
                  <div className="yydis_div">
                    <span className="yydis_name" style={{ whiteSpace: 'nowrap' }} >{info.name}</span><span>:</span>
                  </div>
                  <div className="msg-align">
                    <div className="msg-init">
                      <section>
                        안녕하세요, 1:1담당매니저서비스 이지챗입니다. 근무일 기준(주말, 공휴일제외) 오전 9시~오후 5시까지 상담가능합니다.
                        <br /><br />
                        "문의내역"이라고 전송하면 최근 3개월 문의 진행건이 표시됩니다.
                      </section>
                    </div>
                  </div>
                </div>
            );
            break;

          default://일반 문자 메세지
            let msg = info.message || '';
            yyCompents = (
                <div className="yydis" onClick={()=>{handleYyMsg(info.message_id,info.created_at,item.message_id)}} id={'yydis-' + info.message_id} created_at={info.created_at}>
                  <div className="yydis_div">
                    <span className="yydis_name" style={{ whiteSpace: 'nowrap' }} >{info.name}</span><span>:</span>
                  </div>
                  <div className="msg-align">
                      <strong className="basic-msg yydis_context" dangerouslySetInnerHTML={{ __html: msg.replace(/\n/g, '<br/>') }}></strong>
                  </div>
                </div>
            );
        }
      }
    }

      switch (item.custom_type) {
      case 'attachmentFilename'://파일 메세지
        return (
          <div key={item.message_id} id={'msg-' + item.message_id} className={`chat-msg ${msgPosition}`}>
            {messageAvatar(msgPosition, profileUrl)}
            <div className="msg-align">
              <div className="msg-unification">
                {fileMessageComponent(item, msgBelongTo, yyCompents, masklayer)}
                <div className="msg-setCol">
                  <div className="msg-iconfont">
                    <div onClick={() => {
                      handleMesssageObj(item.message_id)
                    }}>
                      <img src={require("./Common/img/yy.png")} alt=""/>
                    </div>
                    <img className="msg-img" src={require("./Common/img/line.png")} alt=""/>
                    <div onClick={() => {
                      selectContent(item.message_id)
                    }}>
                      <img src={require("./Common/img/fuzhi.png")} alt=""/>
                    </div>
                    <div className="msg-withdraw" onClick={() => {
                      deleteMessage(currentChannel.channel_url, item.message_id)
                    }}>
                      <img className="msg-img" src={require("./Common/img/line.png")} alt=""/>
                      <img src={require("./Common/img/withdraw.png")} alt=""/>
                    </div>
                  </div>
                </div>
              </div>
              <span>{module === 'admin' ? item.user.nickname : ''} {timestampToDate(item.created_at)}</span>
            </div>
            {messageReaders(item)}
          </div>
        );


        case 'clipboardImages'://캡처한 이미지 메세지
          let imageData = JSON.parse(item.data);
          let images = imageData.hasOwnProperty('clipboardImages') ? imageData.clipboardImages : imageData;
          return (
              <div key={item.message_id} id={'msg-' + item.message_id} className={`chat-msg ${msgPosition}`}>
                {messageAvatar(msgPosition, profileUrl)}
                <div className="msg-align">
                  <div className="msg-unification">
                    <div className={`bubble bubble-img ${msgBelongTo}`} id={'bubble-' + item.message_id} binded=""
                         created_at={item.created_at}>
                      {yyCompents}
                      {masklayer}
                      {images.map((item, key_img) => {
                        return (
                            <a key={key_img} href={item} target="_blank" rel="noreferrer">
                              <img src={item} alt=""/>
                            </a>
                        );
                      })}
                </div>
                <div className="msg-setCol">
                  <div className="msg-iconfont">
                    <div onClick={() => {
                      handleMesssageObj(item.message_id)
                    }}>
                      <img src={require("./Common/img/yy.png")} alt=""/>
                    </div>
                    <img className="msg-img" src={require("./Common/img/line.png")} alt=""/>
                    <div onClick={() => {
                      selectContent(item.message_id)
                    }}>
                      <img src={require("./Common/img/fuzhi.png")} alt=""/>
                    </div>
                    <div className="msg-withdraw" onClick={() => {
                      deleteMessage(currentChannel.channel_url, item.message_id)
                    }}>
                      <img className="msg-img" src={require("./Common/img/line.png")} alt=""/>
                      <img src={require("./Common/img/withdraw.png")} alt=""/>
                    </div>
                  </div>
                </div>
              </div>
              <span>{module === 'admin' ? item.user.nickname : ''} {timestampToDate(item.created_at)}</span>
            </div>
            {messageReaders(item)}
          </div>
        );


        case 'consultLeavingId'://문의 메세지
          _data = JSON.parse(item.data);

          if (_data === '') return <div key={item.message_id} id={'msg-' + item.message_id}></div>;

          let adminUrl = getAdminUrl(_data['consultLeavingId']['leavingLevel'], _data['consultLeavingId']['leavingId']);
          return (
              <div key={item.message_id} id={'msg-' + item.message_id} className={`chat-msg ${msgPosition}`}>
                {messageAvatar(msgPosition, profileUrl)}
                <div className="msg-align">
                  <div className="msg-unification">
                    <div className={`bubble ${msgBelongTo}`} id={'bubble-' + item.message_id} binded=""
                         created_at={item.created_at}>
                      {yyCompents}
                      {masklayer}
                      {
                        module === 'admin'
                            ? <a href={adminUrl} target="_blank" rel="noreferrer" className="consult">{item.message}</a>
                            : <strong className="consult">{item.message}</strong>
                      }
                    </div>
                    <div className="msg-setCol">
                      <div className="msg-iconfont">
                        <div onClick={() => {
                          handleMesssageObj(item.message_id)
                        }}>
                          <img src={require("./Common/img/yy.png")} alt=""/>
                        </div>
                        <img className="msg-img" src={require("./Common/img/line.png")} alt=""/>
                        <div onClick={() => {
                          selectContent(item.message_id)
                        }}>
                          <img src={require("./Common/img/fuzhi.png")} alt=""/>
                        </div>
                        <div className="msg-withdraw" onClick={() => {
                          deleteMessage(currentChannel.channel_url, item.message_id)
                        }}>
                          <img className="msg-img" src={require("./Common/img/line.png")} alt=""/>
                          <img src={require("./Common/img/withdraw.png")} alt=""/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <span>{timestampToDate(item.created_at)}</span>
                </div>
              </div>
          );


        case 'utis'://유통인쇼 메세지
          _data = JSON.parse(item.data);

          if (_data === '') return <div key={item.message_id} id={'msg-' + item.message_id}></div>;

          let utisProductUrl = 'https://eggdome.ggook.com/home/product/utisD.php?id=' + _data['id'];
          return (
              <div key={item.message_id} id={'msg-' + item.message_id} className={`chat-msg ${msgPosition}`}>
                {messageAvatar(msgPosition, profileUrl)}
                <div className="msg-align">
                  <div className="msg-unification">
                    <div className={`bubble ${msgBelongTo}`} id={'bubble-' + item.message_id} binded=""
                         created_at={item.created_at}>
                      {yyCompents}
                      {masklayer}
                      <h3 style={{fontSize: '15px', marginBottom: '12px'}}>{item.message}</h3>
                      <a href={utisProductUrl} target="_blank" rel="noreferrer" className="msg-product">
                        <img src={_data.image} alt=""/>
                        <div className="msg-product-info">
                          <h4 title={_data.title}>{_data.title}</h4>
                          <section>
                            <div className="msg-product-price">
                              <strong>{_data.unit_price ? addComma(_data.unit_price) : ''}</strong>
                              {_data.unit_price ? <em>원</em> : ''}
                            </div>
                            {/* <span>x {productData.productNum}</span> */}
                          </section>
                        </div>
                      </a>
                    </div>
                    <div className="msg-setCol">
                      <div className="msg-iconfont">
                        <div onClick={() => {
                          handleMesssageObj(item.message_id)
                        }}>
                          <img src={require("./Common/img/yy.png")} alt=""/>
                        </div>
                        <img className="msg-img" src={require("./Common/img/line.png")} alt=""/>
                        <div onClick={() => {
                          selectContent(item.message_id)
                        }}>
                          <img src={require("./Common/img/fuzhi.png")} alt=""/>
                        </div>
                        <div className="msg-withdraw" onClick={() => {
                          deleteMessage(currentChannel.channel_url, item.message_id)
                        }}>
                          <img className="msg-img" src={require("./Common/img/line.png")} alt=""/>
                          <img src={require("./Common/img/withdraw.png")} alt=""/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <span>{module === 'admin' ? item.user.nickname : ''} {timestampToDate(item.created_at)}</span>
                </div>
              </div>
          );


        case 'consultLeavingOverview'://통계 메세지
          _data = JSON.parse(item.data);
          let overviewData = _data.hasOwnProperty('consultLeavingOverview') && _data['consultLeavingOverview'].hasOwnProperty('leavingOverview') ? _data['consultLeavingOverview']['leavingOverview'] : {};

          return (
              <div key={item.message_id} id={'msg-' + item.message_id} className={`chat-msg ${msgPosition}`}>
                {messageAvatar(msgPosition, profileUrl)}
                <div className="msg-align">
                  <div className="msg-unification">
                    <div className={`bubble ${msgBelongTo}`} id={'bubble-' + item.message_id} binded=""
                         created_at={item.created_at}>
                      {yyCompents}
                      {masklayer}
                      {
                        module === 'admin'
                            ? <strong className="consult">문의내역</strong>
                            : (
                                <div className="overview">
                                  <h3>{item.message}</h3>
                                  <div className="overview-list">
                                    <a href={HOME_PATH + '/leaving/list.php?s=41'} target="_blank" rel="noreferrer">
                                      <span>견적대기</span>
                                      <strong>{overviewData['AA']}</strong>
                                    </a>
                                    <a href={HOME_PATH + '/leaving/list.php?s=43'} target="_blank" rel="noreferrer">
                                      <span>상품결제대기</span>
                                      <strong>{overviewData['AC']}</strong>
                                    </a>
                                    <a href={HOME_PATH + '/leaving/list.php?s=32'} target="_blank" rel="noreferrer">
                                      <span>입고대기</span>
                                      <strong>{overviewData['BB']}</strong>
                                    </a>
                                    <a href={HOME_PATH + '/leaving/list.php?s=34'} target="_blank" rel="noreferrer">
                                      <span>물류결제대기</span>
                                      <strong>{overviewData['BE']}</strong>
                                    </a>
                                    <a href={HOME_PATH + '/leaving/list.php?s=37'} target="_blank" rel="noreferrer">
                                      <span>출고완료</span>
                                      <strong>{overviewData['BK']}</strong>
                                    </a>
                                    <a href={HOME_PATH + '/leaving/list.php?s=31'} target="_blank" rel="noreferrer">
                                      <span>미완료신청건</span>
                                      <strong>{overviewData['BA']}</strong>
                                    </a>
                                  </div>
                                </div>
                            )}
                    </div>
                    <div className="msg-setCol">
                      <div className="msg-iconfont">
                        <div onClick={() => {
                          handleMesssageObj(item.message_id)
                        }}>
                          <img src={require("./Common/img/yy.png")} alt=""/>
                        </div>
                        <img className="msg-img" src={require("./Common/img/line.png")} alt=""/>
                        <div onClick={() => {
                          selectContent(item.message_id)
                        }}>
                          <img src={require("./Common/img/fuzhi.png")} alt=""/>
                        </div>
                        <div className="msg-withdraw" onClick={() => {
                          deleteMessage(currentChannel.channel_url, item.message_id)
                        }}>
                          <img className="msg-img" src={require("./Common/img/line.png")} alt=""/>
                          <img src={require("./Common/img/withdraw.png")} alt=""/>
                        </div>
                      </div>
                    </div>
                  </div>
                  {item.created_at > 0 && <span>{timestampToDate(item.created_at)}</span>}
                </div>
              </div>
          );


        case 'consultLeavingProduct'://상품정보 메세지
          _data = JSON.parse(item.data);
          let productData = _data.hasOwnProperty('consultLeavingProduct') ? _data['consultLeavingProduct'] : {};
          let productUrl = getAdminUrl(_data['consultLeavingProduct']['leavingLevel'], _data['consultLeavingProduct']['leavingId']);

          return (
              <div key={item.message_id} id={'msg-' + item.message_id} className={`chat-msg ${msgPosition}`}>
                {messageAvatar(msgPosition, profileUrl)}
                <div className="msg-align">
                  <div className="msg-unification">
                    <div className={`bubble ${msgBelongTo}`} id={'bubble-' + item.message_id} binded=""
                         created_at={item.created_at}>
                      {yyCompents}
                      {masklayer}
                      <a href={module === 'admin' ? productUrl : productData.productLink} target="_blank"
                         rel="noreferrer"
                         className="msg-product">
                        <img src={productData.productImage} alt=""/>
                        <div className="msg-product-info">
                          <h4 title={productData.productName}>{productData.productName}</h4>
                          <div className="msg-product-opt"
                               title={productData.productOption.replace('&gt;', '\n')}>{productData.productOption}</div>
                          <section>
                            <div className="msg-product-price">
                              <strong>{productData.productPriceKr ? addComma(productData.productPriceKr) : ''}</strong>
                              {productData.productPriceKr ? <em>원</em> : ''}
                            </div>
                            <span>x {productData.productNum}</span>
                          </section>
                        </div>
                      </a>
                    </div>
                    <div className="msg-setCol">
                      <div className="msg-iconfont">
                        <div onClick={() => {
                          handleMesssageObj(item.message_id)
                        }}>
                          <img src={require("./Common/img/yy.png")} alt=""/>
                        </div>
                        <img className="msg-img" src={require("./Common/img/line.png")} alt=""/>
                        <div onClick={() => {
                          selectContent(item.message_id)
                        }}>
                          <img src={require("./Common/img/fuzhi.png")} alt=""/>
                        </div>
                        <div className="msg-withdraw" onClick={() => {
                          deleteMessage(currentChannel.channel_url, item.message_id)
                        }}>
                          <img className="msg-img" src={require("./Common/img/line.png")} alt=""/>
                          <img src={require("./Common/img/withdraw.png")} alt=""/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <span>{module === 'admin' ? item.user.nickname : ''} {timestampToDate(item.created_at)}</span>
                </div>
              </div>
          );


        case 'initMessage'://안내 메세지
          return (
              <div key={item.message_id} id={'msg-' + item.message_id} className={`chat-msg ${msgPosition}`}>
                {messageAvatar(msgPosition, profileUrl)}
                <div className="msg-align">
                  <div className="msg-unification">
                    <div className={`bubble ${msgBelongTo}`} id={'bubble-' + item.message_id} binded=""
                         created_at={item.created_at}>
                      {masklayer}
                      <div className="msg-init">
                        {/* <img src="" alt="" /> */}
                        <section>
                          안녕하세요, 1:1담당매니저서비스 이지챗입니다. 근무일 기준(주말, 공휴일제외) 오전 9시~오후 5시까지 상담가능합니다.
                          <br/><br/>
                          "문의내역"이라고 전송하면 최근 3개월 문의 진행건이 표시됩니다.
                        </section>
                        {/* <a href="" target="_blank" rel="noreferrer"></a> */}
                      </div>
                    </div>
                    <div className="msg-setCol">
                      <div className="msg-iconfont">
                        <div onClick={() => {
                          handleMesssageObj(item.message_id)
                        }}>
                          <img src={require("./Common/img/yy.png")} alt=""/>
                        </div>
                        <img className="msg-img" src={require("./Common/img/line.png")} alt=""/>
                        <div onClick={() => {
                          selectContent(item.message_id)
                        }}>
                          <img src={require("./Common/img/fuzhi.png")} alt=""/>
                        </div>
                        <div className="msg-withdraw" onClick={() => {
                          deleteMessage(currentChannel.channel_url, item.message_id)
                        }}>
                          <img className="msg-img" src={require("./Common/img/line.png")} alt=""/>
                          <img src={require("./Common/img/withdraw.png")} alt=""/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          );


        default://일반 문자 메세지
          let msg = item.message || '';
          return (
              <div key={item.message_id} id={'msg-' + item.message_id} className={`chat-msg ${msgPosition}`}>
                {messageAvatar(msgPosition, profileUrl)}
                <div className="msg-align">
                  <div className="msg-unification">
                    <div className={`bubble ${msgBelongTo}`} id={'bubble-' + item.message_id} binded=""
                         created_at={item.created_at}>
                      {yyCompents}
                      {masklayer}
                      <strong className="basic-msg"
                              dangerouslySetInnerHTML={{__html: activeUrl(msg.replace(/\n/g, '<br/>'))}}></strong>
                    </div>
                    <div className="msg-setCol">
                      <div className="msg-iconfont">
                        <div onClick={() => {
                          handleMesssageObj(item.message_id)
                        }}>
                          <img src={require("./Common/img/yy.png")} alt=""/>
                          </div>
                          <img className="msg-img" src={require("./Common/img/line.png")} alt=""/>
                          <div onClick={() => {
                            selectContent(item.message_id)
                          }}>
                            <img src={require("./Common/img/fuzhi.png")} alt=""/>
                          </div>
                          <div className="msg-withdraw" onClick={() => {
                            deleteMessage(currentChannel.channel_url, item.message_id)
                          }}>
                            <img className="msg-img" src={require("./Common/img/line.png")} alt=""/>
                            <img src={require("./Common/img/withdraw.png")} alt=""/>
                          </div>
                        </div>
                      </div>
                  </div>

                  <span>{module === 'admin' ? item.user.nickname : ''} {timestampToDate(item.created_at)}</span>
                </div>
                {messageReaders(item)}
              </div>
          );
      }
  }


  /**
   * 文本消息中的链接添加a标签
     * @param {*} msg
     * @returns
     */
    const activeUrl = (msg) => {
        // eslint-disable-next-line no-useless-escape
        let reg = /(((https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/ig;
        let result = msg.replace(reg, (item) => {
            return '<a href="' + item + '" target="_blank" style="color:#1a1a1a;">' + item + '</a>';
        });
        return result;
    }


    /**
     * 聊天用户的头像
     * @param {*} pos
     * @returns
     */
    const messageAvatar = (pos, profileUrl) => {
    let component;

    if ((module === 'admin' && pos === 'end') || (module !== 'admin' && pos === 'start')) {
      component = <img src={profileUrl ? profileUrl : require('./Common/img/service.png')} alt="" className="chat-avatar" />;
    } else {
      component = <img src={headerImg} alt="" className="chat-avatar" />;
    }

    return component;
  }


  /**
   * render message 列表
   * @returns 
   */
  const renderMessageList = () => {
    let component;
    if (messages === null) {
      component = (
        <div className="board">
          <Loading size="middle" />
        </div>
      );
    } else {
      const autoReplyTag = ['문의내역'];
      component =
        <div className="board">

          {module === 'admin' &&
            <div className="top">
              <img src={headerImg} alt="" />
              <span>{currentChannel.name}</span>
            </div>}

          <div className="chat" id="chat">
            <div className="chat-board" id="chat-board" onScroll={(e) => { getMoreMessage(e.target.scrollTop) }}>
              {messageLoading ? <div className="board-loading"><Loading size="small" /></div> : ''}

              {messages.map((item) => {
                return messageTypeComponent(item);
              })}
              <div ref={messagesEndRef} />
            </div>
            {yyMessageLoading ? ( <div className="board2">
              <Loading size="yydis" />
            </div>) : ''}
            { quoteBtnView ? <div id="goToQuoteButton" onClick={goToQuote}><img src={require('../src/Common/img/goback.png')} alt="" />인용위치로 돌아가기</div> : ''}
            <div className="auto-reply">
              {autoReplyTag.map((item)=><span key={item} onClick={sendAutoReplyMsg}>{item}</span>)}
            </div>
            {yyMessage ? messageYyComponent(yyMessage) : ''}
            {messageSending ? <div className="sending-load"><img src={require('./Common/img/loading2.gif')} alt="" /></div> : ''}
          </div>

          <div className="write">
            <input type="file" id="fileupload" name="fileupload" onChange={(e) => sendLocalFile(e)} multiple hidden />
            <div className="chat-tool">
              <div className="chat-tool-l">
                <i className="tool-icon icon-crop" data-tip="Windows+Shift+s" onClick={() => { window.close(); }}></i>
                <i className="tool-icon icon-file" onClick={() => { document.getElementById("fileupload").click(); }}></i>
                <ReactTooltip place="top" effect="solid" />
              </div>
              <div className="chat-tool-r">
                {module === 'admin'
                  ? <i className="tool-icon icon-consult" onClick={() => { setConsultView(!consultView) }}></i>
                  : <i className="tool-icon icon-info" onClick={() => { setInfoView(!infoView); }}></i>}
                {/* <i className="tool-icon icon-history"></i> */}
              </div>
            </div>
            <div className="input-box-all">

            <div className="write-box" ref={clipInputRef} id="clipInput" suppressContentEditableWarning={true} contentEditable={contentEditable} onKeyDown={_handleKeyDown} onInput={(e)=>{setDraft(e)}}></div>
            </div>
            <span className="write-link send" onClick={() => { sendMessage() }}></span>
          </div>
        </div>
    }
    return component;
  }


  return (
    <div className="container">

      {auth === 0 ? <LoadingMain /> : ''}

      {module === 'admin' && auth === 1
        ? <Channel channels={channels} currentChannel={currentChannel} setChannel={(channel) => { setChannel(channel) }} setName={(name) => { setChannelName(name) }} />
        : ''}

      {auth === 1 ? renderMessageList() : ''}

      {module === 'admin' && auth === 1 && consultView
        ? <Consult chatId={chatId} user={userInfo} />
        : ''}

      {module !== 'admin' && auth === 1 && infoView
        ? <Info user={userInfo} leaving={leavingInfo} currentChannel={currentChannel} userId={userId} utis_id={utis_id} />
        : ''}

      {auth === 2 ? <Error /> : ''}

        <ContextMenu currentChannel={currentChannel} message_id={contextMessageId} created_at={contextMessageCreatedAt} handleMessage={handleMesssageObj}  withdraw={contextMessageDraw} />
    </div>
  );
}

export default Chat;
