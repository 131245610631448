import React, { useEffect, useRef, useState } from 'react';
import { apiReqs } from './Api/eggdome';
import SendBird from 'sendbird';
import './Common/css/reset.min.css';
import './Common/css/style.css';
import {
    generateUUID,
    timestampToDate,
    yesterday,
    messageFormat,
    messageToArray
} from './Common/js/helper';
import {
    allowedFiles,
    allowedVideo,
} from './Constants';
import {
    getUsers,
    getOpenMessageDatas,
    sendOpenMessageHandle
} from './Api/sendbird';
import { encrypt } from './Common/js/encryption';
import GuestUser from './Components/GuestUser';
import ChatEnd from './Components/ChatEnd';
import Loading from './Components/Loading';
import LoadingMain from './Components/LoadingMain';

let globalAuth = 0;//用于人员已满，重新链接socket时调用，和 state 中 auth 同步

function Guest(props) {
    const [auth, setAuth] = useState(0);//认证状态：0：未认证，1：已认证，2：认证失败（聊天人数已满），3：聊天结束
    const [connection, setConnection] = useState(0);
    const [userId, setUserId] = useState('');//当前sendbird user_id
    const [users, setUsers] = useState([]);//用户列表
    const [userActive, setUserActive] = useState({});//标记用户是否在线
    const [currentUser, setCurrentUser] = useState({});//管理员选中的当前用户
    const [channelUUID, setChannelUUID] = useState(null);//当前channel句柄id
    const [userFlag, setUserFlag] = useState('');//用户的本地标记，用于区分不同用户之间的消息
    const [messages, setMessages] = useState(null);//消息列表
    const [newMessage, setNewMessage] = useState({});//新消息标记，用于更新消息列表以及滚动条下拉到底部
    const [messageSending, setMessageSending] = useState(false);//消息发送loading显示状态，也防止消息重复发送

    const messagesEndRef = useRef(null);//消息框底部标记，初始化或接受新消息时滚动到这个位置

    const UNIFORM_CHANNEL_URL = 'Eggdome_Guest_Channel';
    const ADMIN_KEY = '16585C8EA669CD58479D0ABD1B0E5E2E';
    const ADMIN_ID = 'sellchina';
    const ADMIN_TOKEN = '8c210a1ac5459b70865fdca9346b5b58a6ab022a';
    const MESSAGE_END_FLAG = 'GUEST_OPEN_CHANNEL_END_MESSAGE';

    //url参数，判断是否为管理员（module==ADMIN_KEY
    const queryParams = new URLSearchParams(window.location.search);
    const module = queryParams.get('module');

    const userIds = [
        'Guest_Eggdome_001',
        'Guest_Eggdome_002',
        'Guest_Eggdome_003',
        'Guest_Eggdome_004',
        'Guest_Eggdome_005',
        'Guest_Eggdome_006',
        'Guest_Eggdome_007',
        'Guest_Eggdome_008',
        'Guest_Eggdome_009',
        'Guest_Eggdome_010'
    ];

    //允许上传的mime类型
    const allowedMIME = [
        'image/jpeg',
        'image/png',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'text/plain',
        'application/vnd.ms-excel'
    ];

    //初始化sendbird
    const sb = new SendBird({ appId: process.env.REACT_APP_ID });

    useEffect(() => {
        let user_ids = userIds.join(',');
        let user_id = '';
        let flag = '';
        getUsers(user_ids, async (res) => {
            let users = res.data.users;
            if (module === ADMIN_KEY) {
                user_id = ADMIN_ID;
                setUsers(users);

                setMessages([]);
            } else {
                users.sort(() => Math.random() - 0.5);

                for (let i = 0; i < users.length; i++) {
                    if (users[i].is_online) continue;

                    user_id = users[i].user_id;
                    break;
                }
            }

            if (user_id === '') {
                setAuth(2);
                globalAuth = 2;

                let timer = setInterval(() => {
                    if (globalAuth !== 2) {
                        clearInterval(timer);
                        return;
                    }
                    reconnect();
                }, 5000);

                return;
            }

            if (module !== ADMIN_KEY) {
                //flag 标记不同用户使用相同sendbird user_id时区分消息所属
                flag = localStorage.getItem('user_flag');
                if (!flag) {
                    flag = 'Guest_' + Date.now() + Math.floor(Math.random() * 9000 + 1000);
                    localStorage.setItem('user_flag', flag);
                }
                setUserFlag(flag);

                let histories = localStorage.getItem('messages') || '[]';

                histories = JSON.parse(histories);
                histories.unshift({
                    created_at: 0,
                    custom_type: {
                        flag: flag,
                        user_id: user_id
                    },
                    message: '안녕하세요 에그돔입니다. 로그인이 되어 있지 않아 게스트 상태로 대화가 진행됩니다.',
                    message_id: 0,
                    type: 'MESG',
                    updated_at: 0,
                    user: {
                        is_active: true,
                        nickname: ADMIN_ID,
                        profile_url: '',
                        user_id: ADMIN_ID
                    }
                });

                setMessages(histories);

                scrollToBottom();
            }

            setAuth(1);
            globalAuth = 1;
            setUserId(user_id);

            sb.connect(
                user_id,
                (module === ADMIN_KEY ? ADMIN_TOKEN : ''),
                (user, error) => {
                    if (error) {
                        console.log('connect failed');
                        console.log(error);
                        return false;
                    }

                    sb.OpenChannel.getChannel(UNIFORM_CHANNEL_URL, (openChannel, error) => {
                        if (error) {
                            console.log('get channel failed');
                            console.log(error);
                            return false;
                        }

                        // Call the instance method of the result object in the "openChannel" parameter of the callback function.
                        openChannel.enter((response, error) => {
                            if (error) {
                                console.log('enter channel failed');
                                console.log(error);
                                return false;
                            }

                            console.log('connect success');

                            const channelHandler = new sb.ChannelHandler();

                            channelHandler.onMessageReceived = (channel, message) => {
                                // console.log(channel);
                                // console.log(message);

                                if (channel.url !== UNIFORM_CHANNEL_URL) return;

                                let customType = convert2json(message.customType);

                                //用户接收 MESSAGE_END_FLAG 消息则断开socket
                                if (message.message === MESSAGE_END_FLAG) {
                                    if (module !== ADMIN_KEY && customType.user_id === user_id) {
                                        exitChannel();
                                    }
                                    return;
                                }

                                //用户不属于自己的信息就不做处理
                                if (module !== ADMIN_KEY && customType.flag !== flag) return;

                                let msgData = {
                                    channel_type: message.channelType,
                                    channel_url: message.channelUrl,
                                    created_at: message.createdAt,
                                    custom_type: customType,
                                    message: message.message,
                                    message_id: message.messageId,
                                    type: message.messageType === 'file' ? 'FILE' : 'MESG',
                                    file: {
                                        data: '',
                                        name: message.name,
                                        size: 0,
                                        type: message.type,
                                        url: message.plainUrl
                                    },
                                    updated_at: message.updatedAt,
                                    user: {
                                        is_active: true,
                                        nickname: message._sender.nickname,
                                        profile_url: message._sender.plainProfileUrl,
                                        user_id: message._sender.userId
                                    }
                                }

                                setNewMessage(msgData);
                            }

                            channelHandler.onUserEntered = (openChannel, user) => {
                                if (module !== ADMIN_KEY || openChannel.url !== UNIFORM_CHANNEL_URL) return;

                                user.is_online = true;
                                setUserActive(user);
                            };

                            channelHandler.onUserExited = (openChannel, user) => {
                                if (module !== ADMIN_KEY || openChannel.url !== UNIFORM_CHANNEL_URL) return;

                                user.is_online = false;
                                setUserActive(user);
                            };

                            let _channelUUID = generateUUID();
                            setChannelUUID(_channelUUID);
                            sb.addChannelHandler(_channelUUID, channelHandler);
                        });
                    });
                }
            );
        });
    }, [connection]);// eslint-disable-line react-hooks/exhaustive-deps


    /** 管理员切换用户时获取message_id */
    useEffect(() => {
        if (module === ADMIN_KEY && currentUser.user_id) {//不判断则以下代码执行两次
            getMessages(ADMIN_ID, currentUser.user_id, '*').then(res => {
                let msgs = res;

                if (msgs && msgs.length > 0) {
                    for (let i = msgs.length - 1; i >= 0; i--) {
                        if (msgs[i].user.user_id !== ADMIN_ID) {
                            //根据最后一位用户切换聊天对象
                            setUserFlag(msgs[i].custom_type.flag);
                            break;
                        }
                    }
                }

                scrollToBottom();
            });
        }
    }, [currentUser]);// eslint-disable-line react-hooks/exhaustive-deps


    /** 接收新消息后，修改消息列表，并滚动条滚动到底部 */
    useEffect(() => {
        if (JSON.stringify(newMessage) !== '{}') {//不判断则以下代码执行两次
            //管理员接收不属于当前用户的消息时，则在用户列表中标记未读，不做其他处理
            if (module === ADMIN_KEY && newMessage.custom_type.user_id !== currentUser.user_id) {
                let _users = [...users];

                for (let i = 0; i < _users.length; i++) {
                    if (_users[i].user_id === newMessage.custom_type.user_id) {
                        if (_users[i].unread_count) {
                            _users[i].unread_count += 1;
                        } else {
                            _users[i].unread_count = 1;
                        }
                        _users[i].last_message = newMessage.message;
                        break;
                    }
                }

                setUsers(_users);
            } else {
                const msgs = [...messages, newMessage];

                setMessages(msgs);
                //根据最后一条消息修改聊天对象
                setUserFlag(newMessage.custom_type.flag);

                scrollToBottom();
            }

            //用户端把聊天信息保存到本地
            if (module !== ADMIN_KEY) {
                let saveLength = 20;
                let localMsgs = localStorage.getItem('messages') || '[]';

                localMsgs = JSON.parse(localMsgs);
                localMsgs = localMsgs.filter(item => item.message_id !== newMessage.message_id);
                localMsgs.push(newMessage);

                if (localMsgs.length > saveLength) localMsgs = localMsgs.slice(-saveLength);

                localStorage.setItem('messages', JSON.stringify(localMsgs));
            }

            setNewMessage({});
        }
    }, [newMessage]);// eslint-disable-line react-hooks/exhaustive-deps


    /** 消息内容改变之后重新渲染用户列表 */
    useEffect(() => {
        if (messages && messages.length > 0) {//不判断则以下代码执行两次
            //重新渲染用户列表信息
            let _users = [...users];
            for (let i = 0; i < messages.length; i++) {
                for (let j = 0; j < _users.length; j++) {
                    if (_users[j].user_id === messages[i].user.user_id) {
                        _users[j].last_message = messages[i].message;
                        _users[j].last_time = messages[i].created_at;
                        break;
                    }
                }
            }
            setUsers(_users);
        }
    }, [messages]);// eslint-disable-line react-hooks/exhaustive-deps


    /** 用户链接socket后，修改列表状态 */
    useEffect(() => {
        if (JSON.stringify(userActive) !== '{}') {//不判断则以下代码执行两次
            let _users = [...users];
            for (let i = 0; i < _users.length; i++) {
                if (_users[i].user_id === userActive.userId) {
                    _users[i].is_online = userActive.is_online;
                }
            }

            setUsers(_users);
        }
    }, [userActive]);// eslint-disable-line react-hooks/exhaustive-deps


    const reconnect = () => {
        setConnection(connection + 1);
    }


    /**
     * 接收 MESSAGE_END_FLAG 消息时与socket断开连接
     */
    const exitChannel = () => {
        sb.OpenChannel.getChannel(UNIFORM_CHANNEL_URL, (openChannel, error) => {
            if (error) {
                console.log('get channel failed');
                console.log(error);
                return false;
            }

            openChannel.exit(function (response, error) {
                if (error) {
                    console.log('exit channel failed');
                    console.log(error);
                    return false;
                }

                setAuth(3);
                channelUUID && setChannelUUID(null);

                sb.disconnect(() => {
                    console.log('disconnected');
                });
            });
        });
    }


    /**
     * 获取消息列表
     * @param {*} data 
     * @returns 
     */
    const getMessages = async (admin_id, user_id, flag) => {
        const response = await getOpenMessageDatas(UNIFORM_CHANNEL_URL, admin_id, user_id, flag);

        let _messages = response.data.messages;

        if (_messages.length === 0) {//没有获取到数据则不再往下执行
            messages === null && setMessages([]);//原先 messages 的值为 null, 则初始化设置为 []
            return false;
        }

        //重新组合message数据
        let msgData = [];

        for (let i = 0; i < _messages.length; i++) {
            if (_messages[i].created_at < yesterday()) continue;

            let customType = convert2json(_messages[i].custom_type);
            if (module === ADMIN_KEY && customType.user_id !== currentUser.user_id) continue;

            if (_messages[i].message === MESSAGE_END_FLAG) continue;

            msgData.push({
                channel_type: _messages[i].channel_type,
                channel_url: _messages[i].channel_url,
                created_at: _messages[i].created_at,
                custom_type: customType,
                message: _messages[i].message,
                message_id: _messages[i].message_id,
                type: _messages[i].type,
                file: _messages[i].file,
                updated_at: _messages[i].updated_at,
                user: {
                    is_active: _messages[i].user ? _messages[i].user.is_active : false,
                    nickname: _messages[i].user ? _messages[i].user.nickname : '',
                    profile_url: _messages[i].user ? _messages[i].user.profile_url : '',
                    user_id: _messages[i].user ? _messages[i].user.user_id : '',
                }
            });
        }

        setMessages(msgData);

        scrollToBottom();//初始化消息时，滚动到最底部

        return msgData;
    }


    /**
     * 自定义组合字符串转json
     * @param {*} str 
     * @returns 
     */
    const convert2json = (str) => {
        let datas = {};
        let arr = str.split(';');

        for (let i = 0; i < arr.length; i++) {
            let data = arr[i].split('@');
            datas[data[0]] = data[1];
        }

        return datas;
    }


    /**
     * 图片加载完成后的事件，初始化消息列表时滚动条滚动到底部，加载更多消息时滚动到指定位置
     * @param {*} callback 
     */
    const loadImage = (callback) => {
        let images = document.getElementById('chat').querySelectorAll('img');

        images.forEach((item) => {
            item.onload = () => {
                callback && callback();
            }
        });
    }


    /**
     * 滚动条滚动到底部
     */
    const scrollToBottom = () => {
        setTimeout(() => {
            messagesEndRef.current && messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
            loadImage(() => {//图片加载完成后继续滚动一次
                messagesEndRef.current && messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
            });
        }, 100);
    }

    /**
   * 递归发送消息
   * @param {*} channel_url 
   * @param {*} msgs 
   * @param {*} callback 
   */
    const recursiveSendMessage = (channel_url, msgs, callback) => {
        if (msgs.length === 0) {
            callback();
        } else {
            let msg = msgs.shift();
            let msgData = {};
            let user_id = module === ADMIN_KEY ? currentUser.user_id : userId;

            if (msg['type'] === 'text') {//文字消息发送
                msgData.message = msg['value'];
                msgData.message_type = 'MESG';
                msgData.user_id = userId;
                msgData.custom_type = 'flag@' + userFlag + ';user_id@' + user_id;

                sendOpenMessageHandle(channel_url, msgData, () => {
                    recursiveSendMessage(channel_url, msgs, callback);
                });
            } else if (msg['type'] === 'image') {//输入框图片消息发送
                let imgSrc = msg['value'];

                let user_id_obj = {
                    id: userId
                };
                let userIdEncrypted = encrypt(JSON.stringify(user_id_obj));

                apiReqs.uploadFile({
                    data: {
                        file: imgSrc,
                        userid: userIdEncrypted
                    },
                    success: (res) => {
                        if (res.state === 0) {
                            msgData.message = '';
                            msgData.message_type = 'FILE';
                            msgData.user_id = userId;
                            msgData.custom_type = 'flag@' + userFlag + ';user_id@' + user_id;
                            msgData.url = res.url;

                            sendOpenMessageHandle(channel_url, msgData, () => {
                                setTimeout(() => {//图片处理后等待400毫秒再处理下一个信息
                                    recursiveSendMessage(UNIFORM_CHANNEL_URL, msgs, callback);
                                }, 400);
                            });
                        } else {
                            recursiveSendMessage(channel_url, msgs, callback);
                        }
                    },
                    fail: (err) => {
                        recursiveSendMessage(channel_url, msgs, callback);
                    }
                });
            } else if (msg['type'] === 'file') {//文件消息发送
                let file = msg['value'];

                if (!allowedMIME.includes(file.type) || file.size === 0) {
                    recursiveSendMessage(channel_url, msgs, callback);
                } else {
                    let reader = new FileReader();
                    reader.readAsDataURL(file);

                    reader.onload = () => {
                        let resStr = reader.result;

                        let fileName = file.name;
                        let fileType = file.type;

                        let user_id_obj = {
                            id: userId
                        };
                        let userIdEncrypted = encrypt(JSON.stringify(user_id_obj));

                        apiReqs.uploadFile({
                            data: {
                                file: resStr,
                                userid: userIdEncrypted
                            },
                            success: (res) => {
                                if (res.state === 0) {
                                    let imgUrl = res.url;

                                    msgData.message = imgUrl;
                                    msgData.message_type = 'FILE';
                                    msgData.user_id = userId;
                                    msgData.custom_type = 'flag@' + userFlag + ';user_id@' + user_id;
                                    msgData.url = imgUrl;
                                    msgData.file_name = fileName;
                                    msgData.file_type = fileType;

                                    sendOpenMessageHandle(channel_url, msgData, () => {
                                        setTimeout(() => {//文件处理后等待400毫秒再处理下一个信息
                                            recursiveSendMessage(UNIFORM_CHANNEL_URL, msgs, callback);
                                        }, 800);
                                    });
                                } else {
                                    recursiveSendMessage(channel_url, msgs, callback);
                                }
                            },
                            fail: (err) => {
                                recursiveSendMessage(channel_url, msgs, callback);
                            },
                            done: () => { }
                        });
                    }

                    reader.onerror = (error) => {
                        recursiveSendMessage(channel_url, msgs, callback);
                    };
                }
            }
        }
    }


    /** 消息发送 */
    const sendMessage = (is_end = false) => {
        if (messageSending) return false;

        let user_id = module === ADMIN_KEY ? currentUser.user_id : userId;
        let msgData = {};

        if (is_end) {
            msgData.message = MESSAGE_END_FLAG;
            msgData.message_type = 'MESG';
            msgData.user_id = userId;
            msgData.custom_type = 'flag@' + userFlag + ';user_id@' + user_id;

            sendOpenMessageHandle(UNIFORM_CHANNEL_URL, msgData);
            clearInput();
            return;
        }

        let clipInput = document.querySelector('#clipInput');
        let msg = messageFormat(clipInput.innerHTML).trim();

        if (msg === '') return false;

        setMessageSending(true);
        let msgsData = messageToArray(msg);

        //队列模式发送消息
        recursiveSendMessage(UNIFORM_CHANNEL_URL, msgsData, () => {
            setMessageSending(false);
            //清空输入框
            clearInput();
        });
    }


    /**
     * 发送本地图片文件
     * @param {*} e 
     * @returns 
     */
    const sendLocalFile = async (e) => {
        let files = e.target.files;

        setMessageSending(true);
        let fileDatas = [];

        for (let i = 0; i < files.length; i++) {
            if (!allowedMIME.includes(files[i].type)) {
                continue;
            }

            let _obj = {
                type: 'file',
                value: files[i],
            }

            fileDatas.push(_obj);
        }

        if (fileDatas.length === 0) {
            setMessageSending(false);
            return false;
        }

        //队列模式发送消息
        recursiveSendMessage(UNIFORM_CHANNEL_URL, fileDatas, () => {
            setMessageSending(false);
        });

        //清空文件选择表单
        e.target.value = '';
    }


    /** 管理员按了结束聊天的按钮 */
    const endChat = () => {
        sendMessage(true);

        let user_ids = userIds.join(',');
        getUsers(user_ids, async (res) => {
            setUsers(res.data.users);
        });
    }


    /** 清空输入框 */
    const clearInput = () => {
        setTimeout(() => {
            document.querySelector('#clipInput').innerHTML = "";
        }, 300);
    }


    /**
     * 键盘事件，设置 shift+enter为换行，enter为发送消息
     * @param {*} e 
     */
    const _handleKeyDown = (e) => {
        if (!e.shiftKey && e.key === 'Enter') {
            sendMessage();
        }
    }

    /**
     * 消息类型组件中文件类型组件
     * @param {*} item
     * @returns
     */
    const fileMessageComponent = (item, msgBelongTo) => {
        let fileUrl = item.file.url;
        let fileData = fileUrl.split('/');
        let fileName = fileData[fileData.length - 1];
        let files = fileName.split('.');
        let fileExtension = files[files.length - 1].toLowerCase();
        if (allowedFiles.hasOwnProperty(fileExtension)) {
            return (
                <div className={`bubble bubble-file ${msgBelongTo}`} id={'bubble-' + item.message_id} binded="" created_at={item.created_at}>
                    <a href={fileUrl} target="_blank" rel="noreferrer">
                        <img src={allowedFiles[fileExtension]} alt="" />
                        <em>{item.file.name}</em>
                    </a>
                </div>
            );
        } else if (allowedVideo.includes(fileExtension)) {
            return (
                <div className={`bubble bubble-file ${msgBelongTo}`} id={'bubble-' + item.message_id} binded="" created_at={item.created_at}>
                    <video width="184" controls>
                        <source src={fileUrl} />
                    </video>
                </div>
            );
        } else {
            return (
                <div className={`bubble bubble-img ${msgBelongTo}`} id={'bubble-' + item.message_id} binded="" created_at={item.created_at}>
                    <a href={fileUrl} target="_blank" rel="noreferrer">
                        <img src={fileUrl} alt="" />
                    </a>
                </div>
            );
        }
    }

    /**
     * 消息类型组件
     * @param {*} item 
     * @returns 
     */
    const messageComponent = (item) => {
        let msgPosition;
        let msgBelongTo;

        if (userId === '') return '';

        if (
            (module !== ADMIN_KEY && userIds.includes(item.user.user_id))
            || (module === ADMIN_KEY && item.user.user_id === ADMIN_ID)
        ) {
            msgPosition = 'end';
            msgBelongTo = 'me';
        } else {
            msgPosition = 'start';
            msgBelongTo = 'you';
        }

        let msg = item.message || '';

        switch (item.type) {
            case 'MESG' : //普通文本消息
                return (
                    <div key={item.message_id} id={'msg-' + item.message_id} className={`chat-msg ${msgPosition}`}>
                        {messageAvatar(msgPosition)}
                        <div className="msg-align">
                            <div className={`bubble ${msgBelongTo}`} id={'bubble-' + item.message_id}>
                                <strong className="basic-msg" dangerouslySetInnerHTML={{ __html: msg.replace(/\n/g, '<br/>') }}></strong>
                            </div>
                            {module === ADMIN_KEY && <span style={{ marginBottom: '4px' }}>{item.custom_type.flag}</span>}
                            {item.message_id !== 0 && <span>{timestampToDate(item.created_at)}</span>}
                        </div>
                    </div>
                );

            case 'FILE'://图片 文件消息
                return (
                    <div key={item.message_id} id={'msg-' + item.message_id} className={`chat-msg ${msgPosition}`}>
                        {messageAvatar(msgPosition)}
                        <div className="msg-align">
                            {fileMessageComponent(item, msgBelongTo)}
                            {module === ADMIN_KEY && <span style={{ marginBottom: '4px' }}>{item.custom_type.flag}</span>}
                            {item.message_id !== 0 && <span>{timestampToDate(item.created_at)}</span>}
                        </div>
                    </div>
                );
        }
    }


    const messageAvatar = (pos) => {
        let component;

        if ((module === ADMIN_KEY && pos === 'end') || (module !== ADMIN_KEY && pos === 'start')) {
            component = <img src={require('./Common/img/service.png')} alt="" className="chat-avatar" />;
        } else {
            component = <img src={require('./Common/img/customer.png')} alt="" className="chat-avatar" />;
        }

        return component;
    }


    /**
   * render message 列表
   * @returns 
   */
    const renderMessageList = () => {
        let component;
        if (messages === null) {
            component = (
                <div className="board">
                    <Loading size="middle" />
                </div>
            );
        } else {
            component =
                <div className="board">
                    <div style={{ paddingTop: '20px' }}></div>

                    <div className="chat" id="chat">
                        <div className="chat-board" id="chat-board">

                            {messages.map(item => messageComponent(item))}

                            <div ref={messagesEndRef} />
                        </div>
                        {module === ADMIN_KEY && <button className="end-btn" onClick={() => { endChat() }}>상담종료</button>}
                    </div>

                    <div className="write">
                        <input type="file" id="fileupload" name="fileupload" onChange={(e) => sendLocalFile(e)} multiple hidden />
                        <div className="write-box" id="clipInput" contentEditable="true" onKeyDown={_handleKeyDown}></div>
                        <span className="write-link attach" onClick={() => { document.getElementById("fileupload").click(); }}></span>
                        <span className="write-link send" onClick={() => { sendMessage() }}></span>
                    </div>
                </div>
        }
        return component;
    }


    return (
        <div className="container">

            {(auth === 0 || auth === 2) && <LoadingMain auth={auth} />}

            {auth === 3 && <ChatEnd />}

            {module === ADMIN_KEY && auth === 1
                ? <GuestUser users={users} currentUser={currentUser} setUser={(user) => { setCurrentUser(user) }} />
                : ''}

            {((auth === 1 && module !== ADMIN_KEY) || (auth === 1 && module === ADMIN_KEY && JSON.stringify(currentUser) !== '{}')) && renderMessageList()}
        </div>
    );
}

export default Guest;